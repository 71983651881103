import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterLink} from '@angular/router';

import {MatSortModule} from '@angular/material/sort';
import {MatLegacyTableModule} from '@angular/material/legacy-table';
import {MatLegacyPaginatorModule} from '@angular/material/legacy-paginator';

import {TranslateModule} from "@ngx-translate/core";

import {SharedModule} from '../../shared/shared.module';
import {DynamicTableComponent} from './dynamic-table.component';


@NgModule({
    declarations: [
        DynamicTableComponent,
    ],
    imports: [
        CommonModule,
        MatLegacyTableModule,
        MatSortModule,
        MatLegacyPaginatorModule,
        SharedModule,
        TranslateModule.forChild(),
        RouterLink
    ],
    exports: [
        DynamicTableComponent,

    ]
})
export class DynamicTableModule {
}
