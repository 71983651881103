<mat-toolbar class="d-flex justify-content-between">
    <div class="d-flex align-items-center">
        <button mat-icon-button
            (click)="onGoBack()"
            *ngIf="showBack"
            class="mr-2">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <div class="d-flex flex-column">
            <h1 class="p-0 m-0">
                {{title}}
            </h1>
            <div class="mat-caption text-grey"
                *ngIf="breadcrumb.length">
                {{breadcrumb.join(' | ')}}
            </div>
        </div>
    </div>

    <div>
        <ng-content></ng-content>
    </div>
</mat-toolbar>
<mat-divider></mat-divider>
