import {HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

import {ToastService} from '../services/toast.service';
import {ConfigService} from '../services/config.service';

declare var grecaptcha: any;

@Injectable({
    providedIn: 'root',
})
export class CaptchaService {
    constructor(
        private configService: ConfigService,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {
    }

    getCaptchatoken(): Promise<string> {
        return new Promise((resolve, reject) => {
            grecaptcha.ready(
                () => {
                    grecaptcha
                        .execute(this.configService.config.captcha.siteKey, {action: 'submit'})
                        .then((token) => {
                            resolve(token);
                        })
                        .catch((err) => {
                            this.toastService.error({
                                message: this.translateService.instant('CAPTCHA.EXECUTE_ERROR'),
                            });
                            reject(err);
                        });
                },
                (error) => {
                    this.toastService.error({
                        message: this.translateService.instant('CAPTCHA.NOT_READY'),
                    });
                    reject(error);
                }
            );
        });
    }

    getCaptchaHeader(): Promise<HttpHeaders> {
        return new Promise(async (resolve, reject) => {
            try {
                const token = await this.getCaptchatoken();
                const headers = new HttpHeaders().set('g-token', token);
                resolve(headers);
            } catch (error) {
                this.toastService.error({
                    message: this.translateService.instant('CAPTCHA.ERROR_HEADER'),
                });
                reject(this.translateService.instant('CAPTCHA.ERROR_HEADER'));
            }
        });
    }
}
