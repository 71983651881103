import { Component, OnInit, Input } from '@angular/core';

import { LicenseInstanceService } from 'app/armp/services/license-instance-service';

@Component({
  selector: 'app-license-instance-info',
  templateUrl: './license-instance-info.component.html',
  styleUrls: ['./license-instance-info.component.scss'],
})
export class LicenseInstanceInfoComponent implements OnInit {
  @Input() licenseInstance: any;

  stats: any;
  extraInfoCollapsed = true;

  constructor(private licenseInstanceService: LicenseInstanceService) {}

  ngOnInit() {
    this.licenseInstanceService.getStats(this.licenseInstance.id).then((s) => {
      this.stats = s;
    });
  }
}
