import { Directive, Input } from '@angular/core';
import { CustomValidatorDirective } from 'app/armp/models/custom-validator';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';
import { FormValidators } from 'app/armp/shared/form-validators';

@Directive({
  selector: `[validateFiscalCode][formControlName],[validateFiscalCode][formControl],[validateFiscalCode][ngModel]`,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: FiscalCodeDirective,
      multi: true,
    },
  ],
})
export class FiscalCodeDirective extends CustomValidatorDirective {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('validateFiscalCode') enabled: any;

  protected _validate(c: AbstractControl): ValidationErrors {
    return FormValidators.fiscalCodeValidator(c.value);
  }
}
