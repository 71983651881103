import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

import { ProductService } from 'app/armp/services/product.service';
import { Product } from 'app/armp/models/product';
import { LicenseTemplateService } from 'app/armp/services/license-template.service';
import { CertificateBindingService } from 'app/armp/services/certificate-binding.service';
import { AuthService } from 'app/armp/core/auth.service';
import { LoaderService } from 'app/armp/core/loader.service';
import { ToastService } from 'app/armp/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss'],
})
export class ProductEditComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) form!: NgForm;

  entity;
  licenseTemplates = [];
  certificates = [];
  products = [];

  private subscription: any;

  constructor(
    public activatedRoute: ActivatedRoute,
    private productService: ProductService,
    private licenseTemplateService: LicenseTemplateService,
    private certificateBindingService: CertificateBindingService,
    public authService: AuthService,
    private location: Location,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.entity = new Product();
    this.subscription = this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.productService.getById(+params.id).then((p) => {
          this.entity = p;
          if (this.entity.slugGenerationMode === 'INHERITED') {
            this.entity.inheritedSlug = true;
          }
        });
        this.certificateBindingService
          .getCertificatesByProductId(params.id)
          .then((response) => {
            this.certificates = response;
          });
      }
    });

    this.productService.getAll().then((response) => {
      this.products = response.sort((p1, p2) => {
        if (p1.name > p2.name) {
          return 1;
        }

        if (p1.name < p2.name) {
          return -1;
        }

        return 0;
      });
    });

    this.licenseTemplateService.getAll().then((response) => {
      this.licenseTemplates = response;
    });
  }

  findParentLicenseTemplate(productParentId: number) {
    if (productParentId > 0) {
      this.productService.getById(productParentId).then((productParent) => {
        this.entity.licenseTemplateId = productParent.licenseTemplateId;
      });
    } else {
      this.entity.licenseTemplateId = undefined;
    }
  }

  onBack() {
    this.location.back();
  }

  save() {
    this.form.onSubmit(null);
    if (this.form.valid) {
      this.loaderService.show();

      this.entity.certificates = this.certificates.map((x) => {
        const tmp = {
          id: null,
        };
        Object.assign(tmp, x);

        return tmp;
      });

      this.productService
        .save(this.entity)
        .then(() => {
          this.loaderService.hide();
          this.toastService.success({
            message: this.translateService.instant('TOASTR.SAVE_SUCCESS'),
          });
          this.onBack();
        })
        .catch(() => {
          this.loaderService.hide();
        });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
