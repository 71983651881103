import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-html-dialog',
  templateUrl: './html-dialog.component.html',
  styleUrls: ['./html-dialog.component.scss'],
})
export class HtmlDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: { html: any }) {}

  ngOnInit(): void {}
}
