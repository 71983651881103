import { Identifiable } from 'app/armp/models/identifiable';

export class Certificate extends Identifiable<number> {
  createdOn: string;
  name: string;
  passwordPlaintext: string;
  type: string;
  defaultCertificate: boolean;
  revoked: boolean;
}
