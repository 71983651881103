import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { BitfFile } from 'common/libs/bitforce/models';
import { bitfPromptToDownloadFile } from 'common/libs/bitforce/utils/bitf-files.utils';

import { environment } from 'environments/environment';
import { Page } from 'app/armp/models/page';
import { AuthService } from 'app/armp/core/auth.service';
import { LicenseInstanceService } from 'app/armp/services/license-instance-service';
import { VoucherService } from 'app/armp/services/voucher.service';
import { VoucherPublicService } from 'app/armp/services/voucher-public.service';
import { ToastService } from 'app/armp/services/toast.service';
import { DynamicTableComponent } from 'app/armp/crud/dynamic-table/dynamic-table.component';
import { LoaderService } from 'app/armp/core/loader.service';
import { IDynamicTableColumnData } from 'common/interfaces/dynamic-column.interface';
import { CaptchaService } from 'app/armp/core/captcha.service';

@Component({
  selector: 'app-voucher-license-instances',
  templateUrl: './voucher-license-instances.component.html',
  styleUrls: ['./voucher-license-instances.component.scss'],
})
export class VoucherLicenseInstancesComponent implements OnInit {
  @Input() voucher;
  @Input() isReadonly: boolean;
  @Input() useToken: boolean;

  @Output() licenceUploaded: EventEmitter<any> = new EventEmitter();

  @ViewChild('licenseInstancesTable', { static: true }) licenseInstancesTable!: DynamicTableComponent;
  @ViewChild('licenseInstanceActiveTmpl', { static: true }) licenseInstanceActiveTmpl!: TemplateRef<any>;
  @ViewChild('dateTimeTpl', { static: true }) dateTimeTpl: TemplateRef<any>;
  @ViewChild('machineIdFileUpload') machineIdFileUpload: any;
  @ViewChild('maxReassignmentsForm', { static: true }) maxReassignmentsForm!: NgForm;

  licenseInstanceSearchText: any;
  licenseInstancePage: Page<any>;
  licenseInstanceCols: IDynamicTableColumnData[] = [];
  maxReassignmentsEdit: boolean;
  maxReassignmentsCurrentvalue;

  constructor(
    private licenseInstanceService: LicenseInstanceService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private voucherService: VoucherService,
    public authService: AuthService,
    private httpClient: HttpClient,
    private loaderService: LoaderService,
    private captchaService: CaptchaService
  ) {}

  ngOnInit() {
    this.licenseInstanceCols.push(
      {
        name: 'ID Istanza',
        prop: 'machineUid',
        searchable: true,
      },
      {
        name: 'Hostname',
        prop: 'hostname',
        searchable: true,
      },
      {
        name: 'Stato',
        prop: 'active',
        searchable: false,
        cellTemplate: this.licenseInstanceActiveTmpl,
      },
      {
        name: 'Data Creazione',
        prop: 'createdOn',
        searchable: false,
        cellTemplate: this.dateTimeTpl,
      },
      {
        name: 'Data Ultimo Ping',
        prop: 'lastPingOn',
        searchable: false,
        cellTemplate: this.dateTimeTpl,
      },
      {
        name: 'Scadenza',
        prop: 'expiresOn',
        searchable: false,
        cellTemplate: this.dateTimeTpl,
      }
    );
  }

  fetchLicenseInstances(event: any) {
    if (this.useToken) {
      event.promise = new Promise(async (resolve) => {
        let headers;
        if (this.useToken) {
          headers = await this.captchaService.getCaptchaHeader();
        }
        resolve(
          this.licenseInstanceService.getGridByVoucher(
            event.dataTableRequest,
            this.voucher.code,
            this.voucher.id,
            headers
          )
        );
      });
    } else {
      event.promise = this.licenseInstanceService.getGridByVoucher(
        event.dataTableRequest,
        this.voucher.code,
        this.voucher.id
      );
    }
  }

  licenseInstancesFetched(page: Page<any>) {
    this.licenseInstancePage = page;
  }

  async onStartUploadMachineId(file: BitfFile) {
    this.loaderService.show();

    let headers;
    if (this.useToken) {
      headers = await this.captchaService.getCaptchaHeader();
    }
    this.voucherService
      .uploadMachineId(this.voucher, file.fileObject, headers)
      .then((response: any) => {
        this.loaderService.hide();
        if (response.metadata.errors.length) {
          response.metadata.errors.forEach((e) => {
            this.toastService.error({ message: e.message });
          });
        } else {
          this.licenceUploaded.emit();
          this.toastService.success({
            message: 'Istanza attivata con successo',
          });
          this.refresh();
          this.downloadLicense(response.payload);
        }
      })
      .catch((error) => {
        this.loaderService.hide();
        this.toastService.error({
          message: 'Errore durante il caricamento',
        });
      });
  }

  refresh() {
    this.licenseInstancesTable.fetchPage();
  }

  saveMaxReassignments() {
    if (this.maxReassignmentsForm.valid) {
      this.voucherService.save(this.voucher).then(() => {
        this.toastService.success({
          message: this.translateService.instant('TOASTR.SAVE_SUCCESS'),
        });
        this.maxReassignmentsEdit = false;
        this.maxReassignmentsCurrentvalue = null;
      });
    }
  }

  onEditMaxReassignments() {
    this.maxReassignmentsEdit = !this.maxReassignmentsEdit;
    this.maxReassignmentsCurrentvalue = this.voucher.maxReassignments;
  }

  undoMaxReassignments() {
    this.voucher.maxReassignments = this.maxReassignmentsCurrentvalue;
    this.maxReassignmentsCurrentvalue = null;
    this.maxReassignmentsEdit = false;
  }

  async downloadLicense(licenseInstance) {
    let headers;
    if (this.useToken) {
      headers = await this.captchaService.getCaptchaHeader();
    }
    this.licenseInstanceService
      .downloadLicense(licenseInstance.machineUid, headers)
      .then((file: Blob) => {
        bitfPromptToDownloadFile(
          file,
          'application/zip',
          'zip',
          licenseInstance.machineUid
        );
      });
  }

  canAddLicenseInstance() {
    return (
      this.voucher &&
      this.voucher.active &&
      (!this.authService.isLoggedIn ||
        (this.authService.isLoggedIn &&
          this.authService.hasRole('VOUCHER_CAN_ADD_LICENSE_INSTANCE')))
    );
  }

  async reassign(entity) {
    if (
      confirm(
        this.translateService.instant(
          'VOUCHER.REASSIGN_LICENSE_INSTANCE_CONFIRM'
        )
      )
    ) {
      let headers;
      if (this.useToken) {
        headers = await this.captchaService.getCaptchaHeader();
      }
      this.licenseInstanceService
        .reassign(entity.machineUid, headers)
        .then((e) => {
          entity = e;
          this.toastService.success({
            message: this.translateService.instant(
              'LICENSE_INSTANCE.REASSIGN_SUCCESS'
            ),
          });
          this.refresh();
        });
    }
  }
}
