import { Directive, Input } from '@angular/core';
import { CustomValidatorDirective } from 'app/armp/models/custom-validator';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
} from '@angular/forms';
import { FormValidators } from '../form-validators';

@Directive({
  selector: `[compareSameValue][formControlName],[compareSameValue][formControl],[compareSameValue][ngModel]`,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: CompareSameValueDirective,
      multi: true,
    },
  ],
})
export class CompareSameValueDirective extends CustomValidatorDirective {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('compareSameValue') enabled: any;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('compareSameValueWith') formControlToVerify: AbstractControl;
  @Input('compareSameValueWithInputName') inputName: string;

  protected _validate(formControl: AbstractControl): ValidationErrors {
    return FormValidators.compareSameValueWith(
      formControl.value,
      this.formControlToVerify,
      this.inputName
    );
  }
}
