import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from '../home/home.component';
import {CustomerListComponent} from '../customer/customer-list/customer-list.component';
import {LicenseTemplateListComponent} from '../license-template/license-template-list/license-template-list.component';
import {
  LicenseTemplateCreateComponent
} from '../license-template/license-template-create/license-template-create.component';
import {ProductListComponent} from 'app/armp/product/product-list/product-list.component';
import {ProductEditComponent} from 'app/armp/product/product-edit/product-edit.component';
import {VoucherCreateComponent} from '../voucher/voucher-create/voucher-create.component';
import {VoucherListComponent} from 'app/armp/voucher/voucher-list/voucher-list.component';
import {CustomerCreateComponent} from 'app/armp/customer/customer-create/customer-create.component';
import {VoucherEditComponent} from 'app/armp/voucher/voucher-edit/voucher-edit.component';
import {SystemListComponent} from 'app/armp/system/system-list/system-list.component';
import {SystemEditComponent} from 'app/armp/system/system-edit/system-edit.component';

import {
  LicenseInstanceEditComponent
} from 'app/armp/license-instance/license-instance-edit/license-instance-edit.component';
import {ProfileListComponent} from 'app/armp/profile/profile-list/profile-list.component';
import {ProfileEditComponent} from 'app/armp/profile/profile-edit/profile-edit.component';
import {UserListComponent} from 'app/armp/user/user-list/user-list.component';
import {UserEditComponent} from 'app/armp/user/user-edit/user-edit.component';

import {
  InformationTypeListComponent
} from 'app/armp/information-type/information-type-list/information-type-list.component';
import {
  InformationTypeEditComponent
} from 'app/armp/information-type/information-type-edit/information-type-edit.component';
import {CertificateListComponent} from 'app/armp/certificate/certificate-list/certificate-list.component';
import {CertificateCreateComponent} from 'app/armp/certificate/certificate-create/certificate-create.component';
import {ErrorComponent} from 'app/armp/error/error.component';
import {CustomerEditComponent} from 'app/armp/customer/customer-edit/customer-edit.component';

import {CanActivateViaAuthGuard} from 'app/armp/core/routing/can-activate-via-auth';
import {
  CustomerResolver,
  LicenseInstanceResolver,
  PublicVoucherResolver,
  VoucherResolver,
} from 'app/armp/core/routing/resolvers';
import {CanActivateAdmin} from 'app/armp/core/routing/can-activate-admin';
import {VoucherPublicHomeComponent} from 'app/armp/voucher/voucher-public-home/voucher-public-home.component';
import {VoucherPublicEditComponent} from 'app/armp/voucher/voucher-public-edit/voucher-public-edit.component';
import {CanActivateCaptcha} from '../core/routing/can-activate-captcha';

const routes: Routes = [
    {
        path: '',
        canActivate: [CanActivateViaAuthGuard],
        children: [
            {
                path: '',
                component: HomeComponent,
            },
            {
                path: 'customer',
                component: CustomerListComponent,
            },
            {
                path: 'customer/new',
                component: CustomerCreateComponent,
            },
            {
                path: 'customer/:id',
                component: CustomerEditComponent,
                resolve: {
                    customer: CustomerResolver,
                },
            },
            {
                path: 'license-template',
                component: LicenseTemplateListComponent,
            },
            {
                path: 'license-template/new',
                component: LicenseTemplateCreateComponent,
            },
            {
                path: 'license-template/:id',
                component: LicenseTemplateCreateComponent,
            },
            {
                path: 'product',
                component: ProductListComponent,
            },
            {
                path: 'product/new',
                component: ProductEditComponent,
            },
            {
                path: 'product/:id',
                component: ProductEditComponent,
            },
            {
                path: 'voucher',
                component: VoucherListComponent,
            },
            {
                path: 'voucher/new',
                component: VoucherCreateComponent,
            },
            {
                path: 'voucher/:id',
                component: VoucherEditComponent,
                resolve: {
                    voucher: VoucherResolver,
                },
            },
            {
                path: 'system',
                component: SystemListComponent,
            },
            {
                path: 'system/new',
                component: SystemEditComponent,
            },
            {
                path: 'system/:id',
                component: SystemEditComponent,
            },
            {
                path: 'license-instance/:id',
                component: LicenseInstanceEditComponent,
                resolve: {
                    licenseInstance: LicenseInstanceResolver,
                },
            },
            {
                path: 'profile',
                component: ProfileListComponent,
                canActivate: [CanActivateAdmin],
            },
            {
                path: 'profile/new',
                component: ProfileEditComponent,
                canActivate: [CanActivateAdmin],
            },
            {
                path: 'profile/:id',
                component: ProfileEditComponent,
                canActivate: [CanActivateAdmin],
            },
            {
                path: 'user',
                component: UserListComponent,
            },
            {
                path: 'user/new',
                component: UserEditComponent,
            },
            {
                path: 'user/:id',
                component: UserEditComponent,
            },
            {
                path: 'information-type',
                component: InformationTypeListComponent,
            },
            {
                path: 'information-type/new',
                component: InformationTypeEditComponent,
            },
            {
                path: 'information-type/:id',
                component: InformationTypeEditComponent,
            },
            // {
            //   path: 'admin',
            //   component: AdminComponent,
            // },
            {
                path: 'certificate',
                component: CertificateListComponent,
            },
            {
                path: 'certificate/new',
                component: CertificateCreateComponent,
            },
            {
                path: 'certificate/:id',
                component: CertificateCreateComponent,
            },
            {
                path: 'statistiche',
                loadChildren: () => import('../statistiche/statistiche.module').then(m => m.StatisticheModule)
            },
        ],
    },
    {
        path: 'public',
        canActivate: [CanActivateCaptcha],
        children: [
            {path: '', redirectTo: 'search-voucher', pathMatch: 'full'},
            {
                path: 'search-voucher',
                component: VoucherPublicHomeComponent,
            },
            {
                path: 'voucher/:id',
                component: VoucherPublicEditComponent,
                resolve: {
                    voucher: PublicVoucherResolver,
                },
            },
        ],
    },
    {
        path: 'error',
        component: ErrorComponent,
    },
    {
        path: 'error/:type',
        component: ErrorComponent,
    },
    {
        path: '**',
        redirectTo: '/error/404',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class RoutingModule {
}
