<app-search-bar></app-search-bar>
<div class="p-4">
  <div class="row">
    <div class="col-12">
      <mat-card appearance="outlined">
        <mat-card-title class="d-flex">
          LICENZE
          <span class="flex-grow-1"></span>
          <mat-icon>history_edu</mat-icon>
        </mat-card-title>
        <mat-card-content>
        </mat-card-content>
        <mat-divider inset></mat-divider>
        <mat-card-actions align="end">
          <button mat-raised-button
            color="primary"
            class="mr-2"
            *ngIf="authService.hasRole('LICENSE_TEMPLATE_CAN_WRITE')"
            routerLink="license-template/new">
            <mat-icon class="mr-2">add</mat-icon> Crea template
          </button>
          <button mat-raised-button
            color="primary"
            routerLink="license-template">
            <mat-icon class="mr-2">list</mat-icon> Elenco template
          </button>
        </mat-card-actions>
      </mat-card>
    </div>

    <div class="col-12">
      <mat-card appearance="outlined">
        <mat-card-title class="d-flex">
          VOUCHER
          <span class="flex-grow-1"></span>
          <mat-icon>description</mat-icon>
        </mat-card-title>
        <mat-card-content>
        </mat-card-content>
        <mat-divider inset></mat-divider>
        <mat-card-actions align="end">
          <button mat-raised-button
            color="primary"
            class="mr-2"
            routerLink="voucher/new"
            *ngIf="authService.hasRole('VOUCHER_CAN_WRITE')">
            <mat-icon class="mr-2">add</mat-icon> Crea voucher
          </button>
          <button mat-raised-button
            color="primary"
            routerLink="voucher">
            <mat-icon class="mr-2">list</mat-icon> Elenco voucher
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>