import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPublicCruService } from 'app/armp/crud/services';
import { DataTableRequest } from 'app/armp/models/datatable-request';

@Injectable()
export class LicenseInstancePublicService extends ApiPublicCruService<any> {
  resourceName(): string {
    return 'license-instance';
  }
  getGridByVoucher(
    dataTableRequest: DataTableRequest,
    voucherCode: string,
    voucherId: number,
    headers: HttpHeaders
  ) {
    return this.httpHandler.httpPost(
      `/grid-by-voucher?voucherCode=${voucherCode}&voucherId=${voucherId}`,
      dataTableRequest,
      { headers, responseType: 'json' }
    );
  }

  downloadLicense(machineUID: string, headers: HttpHeaders) {
    return this.httpHandler.httpDownload(`/${machineUID}/download-license`, {
      headers,
      responseType: 'blob',
    });
  }

  reassign(machineUid: number, headers: HttpHeaders) {
    return this.httpHandler.httpPost(`/${machineUid}/reassign`, null, {
      headers,
      responseType: 'json',
    });
  }
}
