import {VersionList} from '../versionList';

export class VersionResponse {
    lastVersion?: boolean;
    versions?: VersionList[]

    constructor(json: any) {
        this.lastVersion = json?.lastVersion ?? false;
        this.versions = json?.versions.map(v => new VersionList(v)) ?? [];
    }
}
