<div class="toolbar p-2 bg-white">
  <form (ngSubmit)="search()"
    #searchForm="ngForm"
    class="w-100">
    <div class="d-flex align-items-center">
      <h2 class="text-uppercase m-0">Cerca</h2>

      <div class="d-flex align-items-center flex-grow-1 ml-4 justify-content-end">

        <div *ngIf="searchTypes.length">
          <mat-form-field appearance="standard"
            class="w-100 form-field-without-padding">
            <mat-label>Tipo ricerca</mat-label>
            <mat-select [(ngModel)]="selectedSearchType"
              name="selectedSearchType">
              <mat-option *ngFor="let searchType of searchTypes"
                [value]="searchType">
                {{searchType.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="searchTypes.length && selectedSearchType"
          class="d-flex align-items-center w-50 ml-4">
          <mat-form-field appearance="standard"
            class="w-100 form-field-without-padding">
            <!-- <mat-icon matSuffix>search</mat-icon> -->
            <mat-label>Trova {{selectedSearchType.label}}</mat-label>
            <input matInput
              type="text"
              name="searchText"
              [(ngModel)]="searchText"
              [placeholder]="selectedSearchType.placeholder">
          </mat-form-field>

          <button mat-icon-button
            (click)="search()"
            class="ml-2">
            <mat-icon>search</mat-icon>
          </button>
        </div>

      </div>

    </div>
  </form>
</div>
<mat-divider></mat-divider>