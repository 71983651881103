import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot,} from '@angular/router';

import {ToastService} from 'app/armp/services/toast.service';
import {LoaderService} from '../loader.service';
import {ConfigService} from '../../services/config.service';

@Injectable()
export class CanActivateCaptcha {
    constructor(
        private configService: ConfigService,
        private router: Router,
        private loaderService: LoaderService,
        private toastService: ToastService
    ) {
    }

    canActivate(
        activatedRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        return new Promise((success, reject) => {
            let timeout = setTimeout(() => {
                this.router.navigate(['/error']);
                this.toastService.error({
                    message: 'Errore nel caricamento della pagina',
                });
                this.loaderService.hide();
                reject(false);
            }, 5000);

            let node = document.createElement('script');
            node.src = `https://www.google.com/recaptcha/api.js?onload=reCaptchaLoad&render=${this.configService.config.captcha.siteKey}`;
            node.type = 'text/javascript';
            node.async = false;
            document.getElementsByTagName('head')[0].appendChild(node);
            (window as any).reCaptchaLoad = () => {
                this.loaderService.hide();
                clearTimeout(timeout);
                success(true);
            };
        });
    }
}
