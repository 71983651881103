import { Injectable } from '@angular/core';

import { ApiPrivateService } from 'app/armp/crud/services';

@Injectable()
export class AdminService extends ApiPrivateService {
  resourceName(): string {
    return 'admin';
  }

  migrate() {
    return this.httpHandler.httpGet('/migrate');
  }

  getMigrationStatus() {
    return this.httpHandler.httpGet('/migration-status');
  }
}
