import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { InformationTypeService } from 'app/armp/services/information-type.service';
import { InformationType } from 'app/armp/models/information-type';
import { AuthService } from 'app/armp/core/auth.service';
import { LoaderService } from 'app/armp/core/loader.service';
import { ToastService } from 'app/armp/services/toast.service';
import { Location } from '@angular/common';
import { BitfFile } from 'common/libs/bitforce/models';

import 'codemirror/mode/xml/xml';
@Component({
  selector: 'app-information-type-edit',
  templateUrl: './information-type-edit.component.html',
  styleUrls: ['./information-type-edit.component.scss'],
})
export class InformationTypeEditComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: false }) private form: NgForm;

  entity: InformationType;

  private subscription: any;

  constructor(
    public activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private informationTypeService: InformationTypeService,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private location: Location
  ) {}

  ngOnInit() {
    this.entity = new InformationType();
    this.subscription = this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.informationTypeService.getById(params.id).then((p) => {
          this.entity = p;
        });
      }
    });
  }

  onStartUploadFile(file: BitfFile) {
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result.toString();
      this.entity.defaultXsl = text;
    };
    reader.readAsText(file.fileObject);
  }

  onBack() {
    this.location.back();
  }

  save() {
    this.form.onSubmit(null);
    if (this.form.valid) {
      this.loaderService.show();

      this.informationTypeService
        .save(this.entity)
        .then(() => {
          this.loaderService.hide();
          this.toastService.success({
            message: this.translateService.instant('TOASTR.SAVE_SUCCESS'),
          });
          this.onBack();
        })
        .catch(() => {
          this.loaderService.hide();
        });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
