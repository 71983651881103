import { Component, OnInit } from '@angular/core';

import { ProfileService } from 'app/armp/services/profile.service';
import { AuthService } from 'app/armp/core/auth.service';
import { IDynamicTableColumnData } from 'common/interfaces/dynamic-column.interface';

@Component({
  selector: 'app-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.scss'],
})
export class ProfileListComponent implements OnInit {
  dataColumns: IDynamicTableColumnData[] = [];
  searchText: string;

  constructor(
    public profileService: ProfileService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.dataColumns.push(
      {
        name: 'Nome',
        prop: 'name',
        sortable: true,
        styleHeader: { width: '300px' },
      },
      {
        name: 'Descrizione',
        prop: 'description',
        sortable: true,
      }
    );
  }

  canDelete(event: { row: any; canDelete: boolean }) {
    event.canDelete = !event.row.system;
  }
}
