import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';

import {firstValueFrom} from 'rxjs';

import {Environment} from '../models/environment';
import {KeycloakService} from 'keycloak-angular';
import {KeycloakOptions} from 'keycloak-angular/lib/core/interfaces/keycloak-options';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    private httpNoInterceptor: HttpClient;
    /**
     * Default config, it will be replaced during initialization ( this.loadConfig() )
     */
    private _config: Environment = {
        production: false,
        apiEndpoint: 'https://localhost:3001',
        apiPublicEndpoint: 'https://localhost:3002',
        logLevel: 4,
        captcha: {
            siteKey: '6LcA0SEeAAAAADOdmhgIybRfu77f2Q1TQNwYkG_E',
        },
        keycloakConfig: {
            url: 'https://login2.devops.aruba.it/auth',
            realm: 'aruba-int',
            clientId: 'armp-pkce',
            redirectUri: 'http://localhost:4200/',
        },
    };

    get config(): Environment {
        return this._config;
    }

    get keycloackOptions(): KeycloakOptions {
        return {
            config: {
                url: this.config.keycloakConfig.url,
                realm: this.config.keycloakConfig.realm,
                clientId: this.config.keycloakConfig.clientId,
            },
            initOptions: {
                onLoad: 'check-sso',
                pkceMethod: 'S256',
                silentCheckSsoRedirectUri:
                    window.location.origin + '/assets/silent-check-sso.html',
                redirectUri: this.config.keycloakConfig.redirectUri,
            }
        };
    }

    constructor(
        private httpClient: HttpClient,
        private handler: HttpBackend
    ) {
        this.httpNoInterceptor = new HttpClient(handler);
    }

    initializer(keycloakService: KeycloakService, configService: ConfigService): () => Promise<void> {
        return (): Promise<void> => {
            return new Promise(async (resolve, reject) => {
                try {
                    await configService.loadConfig();
                } catch (error) {
                    reject();
                    return;
                }

                try {
                    await keycloakService.init(configService.keycloackOptions);
                    resolve();
                } catch (error) {
                    reject();
                }
            });
        };
    }

    /** Load config (environment.json) */
    async loadConfig(): Promise<Environment> {
        try {
            this._config = await firstValueFrom(this.httpNoInterceptor.get<Environment>('/assets/environments/environment.json'));
        } catch (err) {
            console.error(err);
        }
        return this.config;
    }

}
