import { Injectable } from '@angular/core';
import { User } from 'app/armp/models/user';
import { ApiPrivateCrudService } from 'app/armp/crud/services';

@Injectable()
export class UserService extends ApiPrivateCrudService<User> {
  resourceName(): string {
    return 'user';
  }

  getByUsername(username: string): Promise<User> {
    return this.httpHandler.httpGet(`/info`);
  }

  checkPassword(password: string) {
    return this.httpHandler.httpGet(`/check-password?p=${encodeURI(password)}`);
  }
}
