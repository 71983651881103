import {ControlValueAccessor, UntypedFormControl, NgForm, Validator,} from "@angular/forms";
import {Directive, Input, ViewChild} from "@angular/core";

@Directive()
export abstract class CustomControl<T> implements ControlValueAccessor, Validator {
  abstract get errorKey(): string;

  disabled: boolean;

  protected propagateOnChange: (_: any) => () => {};

  protected propagateOnTouched: () => () => {};

  value: T;

  @ViewChild("form")
  protected form: NgForm;

  @Input() topForm: NgForm;

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    if (this.topForm) {
      this.topForm.ngSubmit.subscribe((event) => {
        if (this.topForm.submitted) {
          this.form?.onSubmit(new Event(""));
        }
      });
    }
    if (this.form) {
      this.form.form.valueChanges.subscribe(() => {
        fn(this.value);
      });
    }

    this.propagateOnChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateOnTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.form) {
      this.form.form.disable();
    }

    this.disabled = isDisabled;
  }

  validate(c: UntypedFormControl) {
    if (this.form && this.form.invalid) {
      const e = {};
      e[this.errorKey] = false;
      return e;
    }
  }

  get actualForm() {
    return this.topForm || this.form;
  }
}
