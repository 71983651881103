import { Injectable } from '@angular/core';
import { ApiPublicService } from 'app/armp/crud/services';

@Injectable()
export class CertificateBindingPublicService extends ApiPublicService {
  resourceName(): string {
    return 'certificate-binding';
  }

  getCertificatesByVoucherId(voucherId: number) {
    return this.httpHandler.httpGet('/voucher_id/' + voucherId);
  }
}
