import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { VoucherService } from 'app/armp/services/voucher.service';
import { AuthService } from 'app/armp/core/auth.service';
import { IDynamicTableColumnData } from 'common/interfaces/dynamic-column.interface';

@Component({
  selector: 'app-voucher-list',
  templateUrl: './voucher-list.component.html',
  styleUrls: ['./voucher-list.component.scss'],
})
export class VoucherListComponent implements OnInit {
  dataColumns: IDynamicTableColumnData[] = [];

  @ViewChild('statusColTpl', { static: true })
  private statusColTpl: TemplateRef<any>;

  @ViewChild('voucherActiveTmpl', { static: true })
  private voucherActiveTmpl: TemplateRef<any>;

  @ViewChild('dateTpl', { static: true })
  private dateTpl: TemplateRef<any>;

  @ViewChild('dateTimeTpl', { static: true })
  private dateTimeTpl: TemplateRef<any>;

  searchText = '';

  constructor(
    public voucherService: VoucherService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.loadDataColumns();
  }

  private loadDataColumns() {
    this.dataColumns.push(
      {
        name: 'Codice',
        prop: 'code',
        sortable: true,
        searchable: true,
        styleHeader: { width: '200px' },
      },
      {
        name: 'Stato',
        prop: 'status',
        sortProp: 'statusOrder',
        sortable: false,
        searchable: false,
        cellTemplate: this.statusColTpl,
        styleHeader: { width: '105px' },
      },
      {
        name: 'Attivo',
        prop: 'active',
        sortable: true,
        searchable: false,
        cellTemplate: this.voucherActiveTmpl,
        styleHeader: { width: '80px' },
      },
      {
        name: 'Cliente',
        prop: 'customerDisplayName',
        sortable: true,
        searchable: true,
      },
      {
        name: 'Prodotto',
        prop: 'productName',
        sortable: true,
        searchable: true,
      },
      {
        name: 'Creato il',
        prop: 'createdOn',
        sortable: true,
        searchable: false,
        cellTemplate: this.dateTimeTpl,
      },
      {
        name: 'Scade il',
        prop: 'expiresOn',
        sortable: true,
        searchable: false,
        cellTemplate: this.dateTpl,
      },
      {
        name: '# Licenze',
        prop: 'totalLicenseInstances',
        sortable: true,
        searchable: false,
      }
    );

    if (this.authService.user.profile.name === 'CUSTOMER') {
      this.dataColumns.splice(
        this.dataColumns.findIndex((c) => c.prop === 'customerDisplayName'),
        1
      );
    }
  }
}
