import {Component, OnInit, ViewChild} from '@angular/core';

import {CustomerService} from '../../services/customer.service';
import {VoucherService} from '../../services/voucher.service';
import {ProductService} from '../../services/product.service';
import {LicenseTemplateService} from '../../services/license-template.service';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {PolicyService} from 'app/armp/services/policy.service';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import {UntypedFormControl} from '@angular/forms';

import {InternalResourceService} from 'app/armp/services/internal-resource.service';
import {SystemService} from 'app/armp/services/system.service';
import {AuthService} from 'app/armp/core/auth.service';
import {
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent
} from '@angular/material/legacy-autocomplete';
import {LoaderService} from 'app/armp/core/loader.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from '../../services/toast.service';
import {AutomaticUserBindingService} from 'app/armp/services/automaticuser-binding.service';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher-create.component.html',
  styleUrls: ['./voucher-create.component.scss'],
})
export class VoucherCreateComponent implements OnInit {
  defaults: any;
  systems: any;
  internalContact: any;

  product: any;
  products = [];

  voucher: any = {};
  automaticUser: any;
  customer: any = {};
  customerSearchText: string;
  customerSearchResult: any[];

  licenseTemplates = [];
  selectedPolicies = {};
  policies = [];
  validity = false;
  tomorrow = new Date();
  today = new Date();
  licenseExpirationModes = [];
  newInternalContactVisible = false;
  internalContactDataSource: Observable<any>;
  internalContactFormControl = new UntypedFormControl();
  automaticSignatureDataSource: Observable<any>;
  automaticSignatureFormControl = new UntypedFormControl();
  certificates = [];
  canAddAutomaticUser = false;

  private _licenseTemplate: any = {};
  private licenseExpirationMode: any;
  private policyImplementations = [];
  private expiration = false;
  private searchingResource = false;

  @ViewChild('form', { static: false }) form;

  constructor(
    private customerService: CustomerService,
    public voucherService: VoucherService,
    private productService: ProductService,
    private licenseTemplateService: LicenseTemplateService,
    private location: Location,
    private router: Router,
    private policyService: PolicyService,
    private activatedRoute: ActivatedRoute,
    private internalResourceService: InternalResourceService,
    private systemService: SystemService,
    public authService: AuthService,
    private loaderService: LoaderService,
    private translator: TranslateService,
    private toastService: ToastService,
    private automaticUserBindingService: AutomaticUserBindingService
  ) {
    this.internalContactDataSource =
      this.internalContactFormControl.valueChanges.pipe(
        distinctUntilChanged(),
        debounceTime(200),
        switchMap((text) => this.internalResourceService.search(text))
      );
    this.automaticSignatureDataSource = this.automaticSignatureFormControl.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(200),
      switchMap((text) => this.internalResourceService.search(text))
    );

    this.tomorrow.setDate(this.today.getDate() + 1);
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.voucherService.getDefaults().then((defaults) => {
        this.defaults = defaults;
        this.voucher.maxReassignments = defaults.maxReassignments;
      });

      if (params.id) {
        this.voucherService.getById(+params.id).then((p) => {
          this.voucher = p;
          this.customerService.getById(this.voucher.customerId).then((c) => {
            this.customer = c;
          });

          this.productService.getById(this.voucher.productId).then((c) => {
            this.product = c;
            this.onProductChange();
          });
        });
      }
    });

    this.productService.getAll().then((response) => {
      this.products = response;
    });

    this.voucherService.getLicenseExpirationModes().then((response) => {
      this.licenseExpirationModes = response;
    });

    this.policyService.getImplementations().then((response) => {
      this.policyImplementations = response;
    });

    this.licenseTemplateService.getAll().then((response) => {
      this.licenseTemplates = response;
    });

    this.systemService.getAll().then((systems) => (this.systems = systems));
  }

  searchCustomer() {
    this.loaderService.show();
    this.customerService.search(this.customerSearchText).then((result) => {
      this.customerSearchResult = result;
      this.loaderService.hide();
    });
  }

  goToCustomerDetail(customer) {
    this.router.navigate(['/customer/' + customer.id]);
  }

  selectCustomer(customer) {
    this.customer = customer;
  }

  backToCustomerSearch() {
    this.customer = {};
    this.clearCustomerSearch();
  }

  onBack() {
    this.location.back();
  }

  clearCustomerSearch() {
    this.customerSearchResult = [];
    this.customerSearchText = null;
  }

  internalContactSelected(event: MatAutocompleteSelectedEvent) {
    this.internalContact = event.option.value;
    this.voucher.internalContactId = event.option.value.id;
    this.voucher.internalContactName = event.option.value.name;
    this.voucher.internalContactEmail = event.option.value.email;
    this.voucher.internalContactPhone = event.option.value.phone;
    this.newInternalContactVisible = false;
  }

  newInternalContact() {
    this.internalContact = null;
    this.voucher.internalContactId = null;
    this.newInternalContactVisible = true;
  }

  onProductChange() {
    if (this.product) {
      this.licenseTemplate = this.licenseTemplates.find(
        (t) => t.id === this.product.licenseTemplateId
      );
    }
  }

  onLicenseExpirationModeChange() {
    this.expiration =
      this.voucher.licenseExpirationMode == 'VOUCHER_EXPIRATION';
    this.validity = !(
      this.voucher.licenseExpirationMode == 'VOUCHER_EXPIRATION'
    );
  }

  policyRemoved(policy) {
    if (policy.id) {
      this.selectedPolicies[policy.id] = false;
    }
  }

  onLicenseInstancePolicySelected(policy, selected: boolean) {
    if (selected) {
      let existing = this.policies.find((x) => x.id === policy.id);

      if (!existing) {
        existing = {};
        this.policies.push(existing);
      }

      existing = Object.assign(existing, policy);
      //existing['id'] = null;
    } else {
      this.policies.splice(
        this.policies.findIndex((x) => x.id === policy.id),
        1
      );
    }
  }

  save() {
    this.form.onSubmit(null);
    if (!this.form.valid) {
      return;
    }
    this.loaderService.show();

    this.voucher.active = true;
    this.voucher.customerId = this.customer.id;
    this.voucher.productId = this.product.id;
    this.voucher.licenseTemplateId = this.licenseTemplate
      ? this.licenseTemplate.id
      : null;

    this.voucher.policies = this.policies.map((x) => {
      let tmp = {
        id: null,
        licenseTemplateId: null,
      };
      Object.assign(tmp, x);
      if (tmp.licenseTemplateId) {
        tmp.id = null;
      }

      return tmp;
    });

    this.voucher.certificates = this.certificates.map((x) => {
      let tmp = {
        id: null,
      };
      Object.assign(tmp, x);

      return tmp;
    });

    if (this.automaticUser === undefined || this.automaticUser === null) {
      this.saveVoucher();
      return;
    }
    this.automaticUserBindingService.addUser(this.automaticUser)
      .then((createdUser: any) => {
        this.voucher.automaticSignatureUserId = createdUser.id;
        this.saveVoucher();
      })
      .catch(() => {
        this.loaderService.hide();
      });
  }

  private saveVoucher() {
    this.voucherService
      .save(this.voucher)
      .then(() => {
        this.loaderService.hide();
        this.toastService.success({
          message: this.translator.instant('TOASTR.SAVE_SUCCESS'),
        });
        this.onBack();
      })
      .catch(() => {
        this.loaderService.hide();
      });
  }

  get licenseTemplate() {
    return this._licenseTemplate;
  }
  set licenseTemplate(licenseTemplate: any) {
    this._licenseTemplate = licenseTemplate;
    this.selectedPolicies = {};
    this.policies = [];
    if (licenseTemplate) {
      this.licenseTemplate.policies.forEach((element) => {
        this.selectedPolicies[element.id] = true;
        this.onLicenseInstancePolicySelected(element, true);
      });
    }
  }

  addAutomaticUser() {
    this.automaticUser = {};
    this.canAddAutomaticUser = true;
  }

  removeAutomaticUser() {
    this.canAddAutomaticUser = false;
    this.automaticUser = undefined;
  }

}
