import { TranslateService } from '@ngx-translate/core';
import { bitfToTranslate } from './bitf-translate.utils';
import { BitfFile } from '../models';
import { bitfBytesPipeFn } from '../pipes/bitf-bytes.function';
import { IBitfFileValidationRule } from '../interfaces';

export function bitfValidateFiles(
  filesToValidate: BitfFile | BitfFile[],
  validationRule: IBitfFileValidationRule,
  translateService: TranslateService
): BitfFile | BitfFile[] {
  let files;
  let isArray = true;
  if (!Array.isArray(filesToValidate)) {
    files = [filesToValidate];
    isArray = false;
  } else {
    files = filesToValidate;
  }

  // clear previous state of validations
  files.forEach((f: BitfFile) => (f.validationErrors = []));

  if (
    Array.isArray(validationRule?.extensions) &&
    validationRule.extensions.length
  ) {
    files.forEach((f: BitfFile) => {
      const fileExt = f.fileObject.name.split('.').pop();
      const isExtAllowed = validationRule.extensions?.filter((ext) =>
        f.fileObject.name.toUpperCase().endsWith(ext.toUpperCase())
      );

      if (!isExtAllowed?.length) {
        bitfToTranslate('BITF.FILE_UPLOADER.VALIDATION_EXTENSION_ERROR');
        const error = translateService.instant(
          'BITF.FILE_UPLOADER.VALIDATION_EXTENSION_ERROR',
          {
            value: fileExt,
          }
        );
        f.validationErrors.push(error);
        f.isValid = false;
      }
    });
  }

  if (validationRule?.maxFileSize) {
    files.forEach((f: BitfFile) => {
      if (validationRule.maxFileSize! < f.fileObject.size) {
        bitfToTranslate('BITF.FILE_UPLOADER.VALIDATION_FILE_SIZE_ERROR');
        const error = translateService.instant(
          'BITF.FILE_UPLOADER.VALIDATION_FILE_SIZE_ERROR',
          {
            value: bitfBytesPipeFn(f.fileObject.size, 0),
            max: bitfBytesPipeFn(validationRule.maxFileSize, 0),
          }
        );
        f.validationErrors.push(error);
        f.isValid = false;
      }
    });
  }

  if (!isArray) {
    return files[0];
  }
  return files;
}

export const bitfGenerateFilesList = (filesList: FileList): BitfFile[] => {
  if (!filesList) {
    return [];
  }
  const arrayOfFiles = Array.from(filesList);
  return arrayOfFiles.map((file) => new BitfFile(file));
};

export const bitfPromptToDownloadFile = (
  fileContent: ArrayBuffer | Blob,
  fileType: string,
  fileExtension: string,
  fileName?: string
) => {
  const downloadFile = new Blob([fileContent], { type: fileType });
  const objectUrl = URL.createObjectURL(downloadFile);
  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
    const objectUrlParts = objectUrl.split('/');
    (window.navigator as any).msSaveOrOpenBlob(
      downloadFile,
      fileName
        ? fileName
        : `${objectUrlParts[objectUrlParts.length - 1]}.${fileExtension}`
    );
  } else {
    const a = document.createElement('a');
    a.href = objectUrl;
    if (fileName && fileExtension) {
      a.download = `${fileName}.${fileExtension}`;
    } else if (fileName) {
      a.download = `${fileName}`;
    }
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};
