<div>
  <app-page-header [title]="'ISTANZA: ' + entity.machineUid"
    [breadcrumb]="breadcrumb">
    <div class="d-flex">
      <button mat-raised-button
        color="primary"
        (click)="reassign()"
        *ngIf="entity.active && canEdit && authService.hasRole('VOUCHER_CAN_ADD_LICENSE_INSTANCE')">
        Riassegna</button>
    </div>
  </app-page-header>

  <div class="container-fluid">
    <div class="row">

      <div class="col-24 mt-3"
        *ngIf="!entity.active">
        <app-alert [text]="'L\'istanza è stata disattivata il: '+ (entity.deactivatedOn | date: 'short')"
          type="error"></app-alert>
      </div>

      <div class="col-24 mt-3">
        <app-license-instance-info [licenseInstance]="entity"></app-license-instance-info>
      </div>

      <div class="col-24 mt-3">
        <mat-card appearance="outlined" class="mat-sub-card p-0">
          <mat-tab-group>
            <mat-tab label="{{tab}}"
              *ngFor="let tab of tabs; let tabIndex = index">
              <ng-template matTabContent>

                <div class="p-2"
                  *ngIf="tabIndex=== 0">
                  <app-license-instance-policy [licenseInstance]="entity"
                    [voucher]="voucher"
                    [disabled]="!canEdit"></app-license-instance-policy>
                </div>

                <div class="p-2"
                  *ngIf="tabIndex === 1">
                  <app-dynamic-table #informationGrid
                    [columns]="informationTableCols"
                    (fetchData)="fetchInformations($event)"
                    [sorts]="[{prop: informationTableCols[0].prop, dir: 'desc'}]"
                    [showDeleteBtn]="false"
                    [showDetailLink]="false"
                    tableClass="shadow-none"
                    paginatorClass="shadow-none">
                  </app-dynamic-table>
                </div>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </mat-card>

        <ng-template #informationGridDetailTpl
          let-row="row"
          let-value="value">
          <div class="w-100 d-flex justify-content-end">
            <button mat-icon-button
              (click)="openInformationModal(row)">
              <mat-icon>navigate_next</mat-icon>
            </button>
          </div>
        </ng-template>

        <ng-template #dateTimeTpl
          let-value="value">
          {{value | date: 'short'}}
        </ng-template>
      </div>

    </div>
  </div>

</div>