import {VersionSave} from './request/versionRequest';

export class Version {
    id: number;
    classifier: string;
    major: string;
    minor: string;
    bugfix: string;
    type?: string;

    get isStable(): boolean {
        return !this.type;
    }

    get viewValue(): string {
        return `${this.major}.${this.minor}.${this.bugfix}${this.type ? '.' + this.type : ''}`;
    }

    constructor(json: any, classifier: string) {
        Object.assign(this, json);
        this.classifier = classifier;
    }

    getOggSave(): VersionSave {
        return {
            id: this.id,
            classifier: this.classifier,
            major: this.major,
            minor: this.minor,
            bugfix: this.bugfix,
            type: this.type,
        };
    }
}
