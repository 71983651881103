<app-page-header title="Tipi Informazione"
  [showBack]="false">

  <div class="d-flex align-items-center">
    <button class="mr-4"
      mat-raised-button
      color="primary"
      [routerLink]="['new']"
      *ngIf="authService.hasRole('INFORMATION_TYPE_CAN_WRITE')">
      Crea Tipo Informazione
    </button>

    <bitf-search (valueChanges)="searchText = $event"
      placeholder="Cerca tipo di informazione"
      label="Cerca tipo di informazione"
      formFieldClass="bitf-search-input"
      [isInToolbar]="true"
      [hasSearchIcon]="false">
    </bitf-search>
  </div>

</app-page-header>

<div class="container-fluid py-3">
  <app-dynamic-table [service]="informationTypeService"
    [columns]="dataColumns"
    [showDeleteBtn]="authService.hasRole('INFORMATION_TYPE_CAN_DELETE')"
    [searchText]="searchText">
  </app-dynamic-table>
</div>