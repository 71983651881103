import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';

import {DataTableRequest} from 'app/armp/models/datatable-request';
import {ApiPrivateCrudService} from 'app/armp/crud/services';
import {VoucherFull} from '../models/voucherFull';

@Injectable()
export class VoucherService extends ApiPrivateCrudService<object> {
    resourceName(): string {
        return 'voucher';
    }

    getStatus(voucherId: number, code: string, headers?: HttpHeaders) {
        return this.httpHandler.httpGet('/' + voucherId + '/status');
    }

    setActive(voucherId: number, active: boolean) {
        return this.httpHandler.httpPut(`/${voucherId}/active/${active}`);
    }

    getOrderExternalLink(voucherId: number, code: string) {
        return this.httpHandler.httpGet(`/${voucherId}/order-external-link`);
    }

    getDefaults() {
        return this.httpHandler.httpGet(`/defaults`);
    }

    getLicenseExpirationModes() {
        return this.httpHandler.httpGet('/licenseExpirationModes');
    }

    saveCertificateBindings(voucherid: number, certificates: any) {
        certificates = certificates.filter((c) => {
            delete c.__cid;
            return true;
        });

        const data = new FormData();
        data.append('certificates', JSON.stringify(certificates));

        return this.httpHandler.httpPost(
            '/certificate_bindings/' + voucherid,
            data
        );
    }

    getGridByCustomer(dataTableRequest: DataTableRequest, customerId: number) {
        return this.httpHandler.httpPost(
            '/grid-by-customer?customerId=' + customerId,
            dataTableRequest
        );
    }

    getByCode(code: string): Promise<VoucherFull> {
        return this.httpHandler.httpGet('/code/' + code);
    }

    getVouchersDashboard(searchText: string) {
        return this.httpHandler.httpGet(
            '/dashboard?searchText=' + (searchText ? searchText : '')
        );
    }

    uploadMachineId(voucher: any, file: File, headers?: HttpHeaders) {
        const data = new FormData();
        data.append('certificateFile', file);

        return this.httpHandler.httpPost(`/${voucher.id}/machine-id`, data);
    }
}
