<mat-card appearance="outlined" class="mat-sub-card">
  <mat-card-title>Caratteristiche Istanza</mat-card-title>
  <mat-card-content>
    <div class="row mb-3">
      <div class="col-24 col-sm-8 mt-2">
        <ng-container
          *ngTemplateOutlet="item;context:{icon:'event', title:'Scadenza', content: expiresOnTemplate}">
        </ng-container>
        <ng-template #expiresOnTemplate>{{licenseInstance.expiresOn | date: 'shortDate'}}</ng-template>
      </div>

      <div class="col-24 col-sm-8 mt-2"
        *ngIf="licenseInstance.operatingSystemVersion">
        <ng-container
          *ngTemplateOutlet="item;context:{icon:'computer', title:'Sistema Operativo(Versione)[Architettura]:', content: operatingSystemVersionTemplate}">
        </ng-container>
        <ng-template #operatingSystemVersionTemplate>
          {{licenseInstance.operatingSystem}}
          <span *ngIf="licenseInstance.operatingSystemVersion">
            ({{licenseInstance.operatingSystemVersion}})
          </span>
          [{{licenseInstance.operatingSystemArchitecture}}]
        </ng-template>
      </div>

      <div class="col-24 col-sm-8 mt-2"
        *ngIf="licenseInstance.runtime">
        <ng-container
          *ngTemplateOutlet="item;context:{icon:'update', title:'Runtime(Versione):', content: runtimeTemplate}">
        </ng-container>
        <ng-template #runtimeTemplate>{{licenseInstance.runtime}}({{licenseInstance.runtimeVersion}})
        </ng-template>
      </div>

      <div class="col-24 col-sm-8 mt-2"
        *ngIf="licenseInstance.container">
        <div class="d-flex flex-column align-items-center justify-content-center">
          <ng-container
            *ngTemplateOutlet="item;context:{icon:'event', title:'Container(Versione):', content: containerTemplate}">
          </ng-container>
          <ng-template #containerTemplate>{{licenseInstance.container}}({{licenseInstance.containerVersion}})
          </ng-template>
        </div>
      </div>

      <div class="col-24 col-sm-8 mt-2"
        *ngIf="licenseInstance.cpuModel">
        <ng-container
          *ngTemplateOutlet="item;context:{icon:'build', title:'Hardware', content: cpuModelTemplate}">
        </ng-container>
        <ng-template #cpuModelTemplate>
          <strong>CPU:</strong> {{licenseInstance.cpuModel}}
          <br>
          <strong>Memoria:</strong> {{licenseInstance.memory}}
          <br>
          <strong>Disco
            Libero:</strong> {{licenseInstance.diskSpace}}
        </ng-template>
      </div>

      <div class="col-24 col-sm-8 mt-2"
        *ngFor="let topInfo of stats?.topAttributes;let index = index">
        <ng-container
          *ngTemplateOutlet="item;context:{icon:'settings', title: topInfo.label, content: topAttributesTemplate}">
        </ng-container>
        <ng-template #topAttributesTemplate>
          {{topInfo.value}}
        </ng-template>
      </div>

    </div>

    <mat-accordion>
      <mat-expansion-panel class="mat-elevation-z0 bg-grey-light">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Caratteristiche aggiuntive
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div>
          <mat-list>
            <mat-list-item *ngFor="let info of stats?.extraInfos">
              <h4 class="font-weight-bold"
                mat-line>{{info.label}}</h4>
              <div mat-line> {{info.value}} </div>
            </mat-list-item>
          </mat-list>
        </div>

      </mat-expansion-panel>
    </mat-accordion>

  </mat-card-content>
</mat-card>


<ng-template #item
  let-icon="icon"
  let-title="title"
  let-content="content">
  <div class="d-flex flex-column align-items-center justify-content-center h-100 border rounded p-3">
    <div class="d-flex align-items-center">
      <mat-icon>{{icon}}</mat-icon>
      <h5 class="font-weight-bold m-0 ml-2">{{title}}</h5>
    </div>
    <span class="mt-2 text-break">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </span>
  </div>
</ng-template>