import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { AuthService } from 'app/armp/core/auth.service';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable()
export class CanActivateViaAuthGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private authService: AuthService
  ) {
    super(router, keycloak);
  }

  async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    if (!this.authenticated) {
      await this.keycloak.login();
    }

    const isLoggedIn = await this.keycloak.isLoggedIn();

    if (isLoggedIn) {
      await this.authService.getMe();
    } else {
      return false;
    }

    return true;
  }
}
