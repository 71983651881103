import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';

// Modules
import {MATERIAL_MODULES} from 'app/material-modules';
import {Ng2GoogleChartsModule} from 'ng2-google-charts';

// Directives
import {
    BitfTooltipDirective
} from 'common/libs/bitforce/directives/bitf-tooltip/bitf-mat-tooltip/bitf-tooltip.directive';
import {MaxLengthDirective} from './form-validator/max-length.directive';
import {MinDirective} from 'app/armp/shared/form-validator/min.directive';
import {FiscalCodeDirective} from 'app/armp/shared/form-validator/fiscal-code.directive';
import {EmailValidatorDirective} from 'app/armp/shared/form-validator/email-validator.directive';
import {PasswordValidatorDirective} from 'app/armp/shared/form-validator/password-validator.directive';
import {MaxDirective} from 'app/armp/shared/form-validator/max.directive';
import {VatCodeDirective} from 'app/armp/shared/form-validator/vat-code.directive';

// Components
import {
    BitfTooltipContainerComponent
} from 'common/libs/bitforce/directives/bitf-tooltip/bitf-tooltip-container/bitf-tooltip-container.component';
import {BitfSearchComponent} from 'common/libs/bitforce/core/components/ui/search/bitf-search.component';
import {BitfDndFileComponent} from 'common/libs/bitforce/core/components/ui/dnd-file';
import {
    BitfMatOkCancelDialogComponent
} from 'common/libs/bitforce/core/components/ui/bitf-mat-ok-cancel-dialog/bitf-mat-ok-cancel-dialog.component';
import {FormControlErrorComponent} from 'app/armp/shared/form-control-error/form-control-error.component';
import {SearchBarComponent} from 'app/armp/shared/search-bar/search-bar/search-bar.component';
import {SectionHeaderComponent} from 'app/armp/shared/section-header/section-header.component';
import {PageHeaderComponent} from './page-header/page-header.component';
import {PieChartComponent} from './pie-chart/pie-chart.component';
import {FileUploaderComponent} from './file-uploader/file-uploader.component';
import {AlertComponent} from './alert/alert.component';
import {HtmlDialogComponent} from './html-dialog/html-dialog.component';
import {LoaderComponent} from './loader/loader.component';
import {CompareSameValueDirective} from './form-validator/compare-same-value.directive';

const BITF_COMPONENTS = [
    BitfTooltipDirective,
    BitfTooltipContainerComponent,
    BitfSearchComponent,
    BitfDndFileComponent,
    BitfMatOkCancelDialogComponent,
];

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        FormsModule,
        ReactiveFormsModule,
        Ng2GoogleChartsModule,
        ...MATERIAL_MODULES,
    ],
    declarations: [
        MinDirective,
        MaxDirective,
        CompareSameValueDirective,
        FiscalCodeDirective,
        VatCodeDirective,
        PasswordValidatorDirective,
        EmailValidatorDirective,
        MaxLengthDirective,
        FormControlErrorComponent,
        SearchBarComponent,
        SectionHeaderComponent,
        PageHeaderComponent,
        PieChartComponent,
        FileUploaderComponent,
        ...BITF_COMPONENTS,
        AlertComponent,
        HtmlDialogComponent,
        LoaderComponent,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaxLengthDirective,
        MinDirective,
        FiscalCodeDirective,
        EmailValidatorDirective,
        PasswordValidatorDirective,
        MaxDirective,
        CompareSameValueDirective,
        VatCodeDirective,
        FormControlErrorComponent,
        SearchBarComponent,
        SectionHeaderComponent,
        PageHeaderComponent,
        Ng2GoogleChartsModule,
        PieChartComponent,
        FileUploaderComponent,
        AlertComponent,
        HtmlDialogComponent,
        LoaderComponent,
        ...MATERIAL_MODULES,
        ...BITF_COMPONENTS,
    ],
})
export class SharedModule {
}
