import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { UserService } from 'app/armp/services/user.service';
import { AuthService } from 'app/armp/core/auth.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  @ViewChild('booleanTpl', { static: true }) booleanTpl: TemplateRef<any>;

  dataColumns: any[] = [];
  searchText: string;

  constructor(
    public userService: UserService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.dataColumns.push(
      {
        name: 'Nome Utente',
        prop: 'username',
        sortable: true,
      },
      {
        name: 'Nominativo',
        prop: 'displayName',
        sortable: true,
      },
      {
        name: 'Dominio',
        prop: 'domain',
        sortable: true,
      },
      {
        name: 'Profilo Utente',
        prop: 'profile.name',
        sortable: false,
        searchable: false,
      },
      {
        name: 'Utente Locale',
        prop: 'local',
        sortable: false,
        cellTemplate: this.booleanTpl,
      }
    );
  }
}
