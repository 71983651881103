import {HttpClient} from '@angular/common/http';

import {TranslateService} from '@ngx-translate/core';

import {ToastService} from '../../services/toast.service';
import {ApiHttpHandler} from 'app/armp/core/api/api-http-handler';
import {ConfigService} from '../../services/config.service';

export abstract class ApiService {
    apiEndpoint: string;
    httpHandler: ApiHttpHandler;

    constructor(
        apiEndpoint: string,
        protected toastService: ToastService,
        protected http: HttpClient,
        protected translator: TranslateService
    ) {
        this.apiEndpoint = apiEndpoint;
        this.httpHandler = new ApiHttpHandler(
            `${this.resourceName()}`,
            this.toastService,
            this.http,
            this.translator,
            this.apiEndpoint
        );
    }

    abstract resourceName(): string;
}
