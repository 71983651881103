import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import {PlatformLocation, registerLocaleData} from '@angular/common';


import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';

import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {ArmpModule} from './armp/armp.module';
import {ArmpComponent} from './armp/armp/armp.component';
import {RoutingModule} from './armp/routing/routing.module';
import {ConfigService} from './armp/services/config.service';

import {CustomHttpInterceptor} from './armp/core/http/custom-http-interceptor';
import {AuthService} from './armp/core/auth.service';
import {ToastService} from './armp/services/toast.service';

export function initializer(keycloakService: KeycloakService, configService: ConfigService): () => Promise<void> {
    return (): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                await configService.loadConfig();
                await keycloakService.init(configService.keycloackOptions);
                resolve();
            } catch (error) {
                console.error(error);
                reject();
                return;
            }
        });
    };
}

registerLocaleData(localeIt);

export function translateLoader(
    http: HttpClient,
    platformLocation: PlatformLocation
) {
    return new TranslateHttpLoader(
        http,
        platformLocation.getBaseHrefFromDOM() + 'assets/i18n/'
    );
}


@NgModule({
    imports: [
        RoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        ArmpModule,
        HttpClientModule,
        KeycloakAngularModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoader,
                deps: [HttpClient, PlatformLocation],
            },
        }),
    ],
    declarations: [],
    bootstrap: [ArmpComponent],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            multi: true,
            deps: [KeycloakService, ConfigService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomHttpInterceptor,
            multi: true,
            deps: [AuthService, ToastService, TranslateService],
        },
        {provide: LOCALE_ID, useValue: 'it-IT'},
    ],
})
export class AppModule {
}
