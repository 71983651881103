import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

import { SystemService } from 'app/armp/services/system.service';
import { System } from 'app/armp/models/system';
import { AuthService } from 'app/armp/core/auth.service';
import { LoaderService } from 'app/armp/core/loader.service';
import { ToastService } from 'app/armp/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-system-edit',
  templateUrl: './system-edit.component.html',
  styleUrls: ['./system-edit.component.scss'],
})
export class SystemEditComponent implements OnInit {
  @ViewChild('form', { static: true }) form!: NgForm;

  entity = new System();

  constructor(
    public activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private systemService: SystemService,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private location: Location
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.systemService.getById(+params.id).then((e) => {
          this.entity = e;
        });
      }
    });
  }

  onBack() {
    this.location.back();
  }

  save() {
    this.form.onSubmit(null);
    if (this.form.valid) {
      this.loaderService.show();

      this.systemService
        .save(this.entity)
        .then(() => {
          this.loaderService.hide();
          this.toastService.success({
            message: this.translateService.instant('TOASTR.SAVE_SUCCESS'),
          });
          this.onBack();
        })
        .catch(() => {
          this.loaderService.hide();
        });
    }
  }
}
