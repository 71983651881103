import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FormFieldErrorDirective} from './form-field-error.directive';

@NgModule({
    declarations: [
        FormFieldErrorDirective
    ],
    exports: [
        FormFieldErrorDirective
    ],
    imports: [
        CommonModule
    ]
})
export class FormValidatorModule {
}
