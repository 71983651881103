import { Component, Input, OnInit } from '@angular/core';

import { GoogleChartInterface } from 'ng2-google-charts';
import { VoucherService } from 'app/armp/services/voucher.service';
import { CONSTANTS } from 'common/constants/constants';

@Component({
  selector: 'app-vouchers-dashboard',
  templateUrl: './vouchers-dashboard.component.html',
  styleUrls: ['./vouchers-dashboard.component.scss'],
})
export class VouchersDashboardComponent implements OnInit {
  @Input()
  set searchText(val: string) {
    this._searchText = val;
    this.refreshDashboard();
  }
  get searchText() {
    return this._searchText ?? '';
  }

  private _defaultColor = CONSTANTS.COLORS.lightGrey;
  private _colors = [
    CONSTANTS.COLORS.darkRed,
    CONSTANTS.COLORS.orange,
    CONSTANTS.COLORS.green,
    CONSTANTS.COLORS.grey,
  ];
  private _searchText?: string = undefined;

  dashboard: any;
  chartData: any = [];
  chartOptions: GoogleChartInterface['options'] = {
    pieHole: 0.7,
  };

  constructor(private voucherService: VoucherService) {}

  ngOnInit() {
    if (this.searchText === undefined) {
      this.refreshDashboard();
    }
  }

  refreshDashboard() {
    this.voucherService.getVouchersDashboard(this.searchText || '').then((response) => {
      this.dashboard = response;

      this.chartData = [
        ['', ''],
        ['Non validi: ' + this.dashboard.totalInvalid, this.dashboard.totalInvalid],
        ['Attenzione: ' + this.dashboard.totalWarning, this.dashboard.totalWarning],
        ['Validi: ' + this.dashboard.totalValid, this.dashboard.totalValid],
        ['Non attivi: ' + this.dashboard.totalInactive, this.dashboard.totalInactive],
      ];

      if (this.dashboard.total === 0) {
        this.chartOptions.colors = [this._defaultColor];
        this.chartData[1][1] = 100;
      } else {
        this.chartOptions.colors = this._colors;
      }
    });
  }
}
