<h2 mat-dialog-title>Dettaglio infromazione</h2>
<mat-dialog-content>
    <div *ngIf="dialogData.html"
        [innerHTML]="dialogData.html">
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button
        color="primary"
        mat-dialog-close
        cdkFocusInitial>Chiudi</button>
</mat-dialog-actions>