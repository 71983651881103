<div class="heading with-back container-fluid">
  <div class="row">
    <div class="col-sm-14 no-border">
      <div class="row">
        <div class="col-sm-2 col-xs-2 back-arrow"
          *ngIf="showBack">
          <a href="javascript:void(0)"
            (click)="goBack()"><span class="icon-arrow-left-circle go-back"></span></a>
        </div>
        <div class="col-sm-20 col-xs-20">
          <div class="breadcrumb"
            *ngIf="breadcrumb.length">
            <p>{{breadcrumb.join(' | ')}} |</p>
          </div>
          <h2 class="text-uppercase"
            [innerHTML]="title"></h2>
        </div>
      </div>
    </div>
    <div class="col-sm-10"
      *ngIf="headerLinksTemplate">
      <nav class="navbar navbar-default text-right">
        <div class="collapse navbar-collapse">
          <ng-container *ngTemplateOutlet="headerLinksTemplate"></ng-container>
        </div><!-- /.navbar-collapse -->
      </nav>
    </div>
  </div>
</div>