import { Injectable } from '@angular/core';

import { ApiPrivateCrudService } from 'app/armp/crud/services';

@Injectable()
export class PolicyInstanceService extends ApiPrivateCrudService<any> {
  resourceName(): string {
    return 'policy-instance';
  }

  getByLicenseInstance(licenseInstanceId: number) {
    return this.httpHandler.httpGet('/licenseInstance/' + licenseInstanceId);
  }
}
