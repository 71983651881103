import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { BitfFile } from 'common/libs/bitforce/models';
import { bitfPromptToDownloadFile } from 'common/libs/bitforce/utils/bitf-files.utils';

import { CertificateService } from 'app/armp/services/certificate.service';
import { Certificate } from 'app/armp/models/certificate';
import { AuthService } from 'app/armp/core/auth.service';
import { ToastService } from 'app/armp/services/toast.service';
import { LoaderService } from 'app/armp/core/loader.service';

@Component({
  selector: 'app-certificate-create',
  templateUrl: './certificate-create.component.html',
  styleUrls: ['./certificate-create.component.scss'],
})
export class CertificateCreateComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: false }) private form: NgForm;

  certificate: any;
  certificateTypes = [];
  ripetiPassword = '';
  selectedFile: BitfFile;

  private subscription = new Subscription();

  constructor(
    public activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private certificateService: CertificateService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private location: Location,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.certificate = new Certificate();
    this.subscription = this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.certificateService.getById(+params.id).then((p) => {
          this.certificate = p;
          this.certificate.passwordPlaintext = this.ripetiPassword =
            this.certificate.password;
        });
      } else {
        this.certificate.defaultCertificate = false;
        this.certificate.revoked = false;
      }
    });

    this.certificateService.getCertificateTypes().then((response) => {
      this.certificateTypes = response;
    });
  }

  onStartUploadFile(file: BitfFile) {
    this.selectedFile = file;
    this.toastService.success({ message: 'File selezionato correttamente' });
  }

  onBack() {
    this.location.back();
  }

  save() {
    this.form.onSubmit(null);
    if (
      this.certificate.passwordPlaintext !== this.ripetiPassword &&
      this.certificate.passwordPlaintext !== undefined
    ) {
      this.toastService.error({
        message: 'Le password non corrispondono',
      });
    } else {
      if (this.certificate.id > 0 && !this.selectedFile) {
        this.loaderService.show();
        this.certificateService
          .save(this.certificate)
          .then(() => {
            this.loaderService.hide();
            this.toastService.success({
              message: this.translateService.instant('TOASTR.SAVE_SUCCESS'),
            });
            this.onBack();
          })
          .catch(() => {
            this.loaderService.hide();
          });
      } else {
        if (this.selectedFile) {
          this.loaderService.show();
          this.certificateService
            .saveCertificate(this.certificate, this.selectedFile.fileObject)
            .then(() => {
              this.loaderService.hide();
              this.toastService.success({
                message: this.translateService.instant('TOASTR.SAVE_SUCCESS'),
              });
              this.onBack();
            })
            .catch(() => {
              this.loaderService.hide();
            });
        } else {
          this.loaderService.hide();
        }
      }
    }
  }

  downloadCertificate(certificate) {
    this.certificateService
      .downloadCertificate(certificate.id)
      .then((file: Blob) => {
        bitfPromptToDownloadFile(
          file,
          'application/zip',
          'zip',
          certificate.name
        );
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
