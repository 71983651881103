import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));

/** old loader */
/*
environmentLoader.then((dynamicEnvironment) => {
  if (dynamicEnvironment.production) {
    enableProdMode();
  }

  Object.assign(environment, dynamicEnvironment);

  platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
});
*/
