import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { CertificateService } from 'app/armp/services/certificate.service';
import { AuthService } from 'app/armp/core/auth.service';

@Component({
  selector: 'app-certificate-list',
  templateUrl: './certificate-list.component.html',
  styleUrls: ['./certificate-list.component.scss'],
})
export class CertificateListComponent implements OnInit {
  @ViewChild('dateTimeTpl', { static: true }) dateTimeTpl: TemplateRef<any>;
  @ViewChild('booleanTpl', { static: true }) booleanTpl: TemplateRef<any>;

  searchText: string;
  dataColumns: any = [];

  constructor(
    public certificateService: CertificateService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.dataColumns.push(
      {
        name: 'Nome',
        prop: 'name',
        sortable: true,
      },
      {
        name: 'Tipo',
        prop: 'type',
        sortable: true,
      },
      {
        name: 'Default',
        prop: 'defaultCertificate',
        sortable: true,
        cellTemplate: this.booleanTpl,
      },
      {
        name: 'Revocato',
        prop: 'revoked',
        sortable: true,
        cellTemplate: this.booleanTpl,
      },
      {
        name: 'Creato il',
        prop: 'createdOn',
        sortable: true,
        searchable: false,
        cellTemplate: this.dateTimeTpl,
      }
    );
  }
}
