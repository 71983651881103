<table mat-table
       *ngIf="cols && rows && rows.length"
       [dataSource]="rows"
       class="w-100 mat-elevation-z2"
       [ngClass]="tableClass"
       matSort
       (matSortChange)="onSort($event)"
       [matSortActive]="sort?.active"
       [matSortDirection]="sort?.direction">

    <ng-container *ngFor="let column of cols"
                  matColumnDef="{{column.prop || 'links'}}">

        <!-- Header -->
        <th mat-header-cell
            *matHeaderCellDef
            class="{{column.classHeader}}"
            [ngStyle]="column.styleHeader"
            mat-sort-header
            [disabled]="!column.sortable">
            {{column.name}}
        </th>

        <!-- Cell -->
        <td mat-cell
            *matCellDef="let item"
            class="{{column.classRow}}"
            [ngStyle]="column.styleRow">
            <ng-container *ngIf="column.cellTemplate"
                          [ngTemplateOutlet]="column.cellTemplate"
                          [ngTemplateOutletContext]="{row:item, value: item[column.prop]}"></ng-container>
            <span *ngIf="!column.cellTemplate">
                {{item[column.prop]}}
            </span>
        </td>

    </ng-container>

    <tr mat-header-row
        *matHeaderRowDef="columnIdentifiers"></tr>
    <tr mat-row
        *matRowDef="let row; columns: columnIdentifiers;"></tr>

</table>
<mat-paginator *ngIf="cols && rows && rows.length"
               [length]="pagination.length"
               [pageSize]="pagination.pageSize"
               [pageSizeOptions]="[5, 10, 25, 100]"
               [pageIndex]="pagination.pageIndex"
               (page)="onPageChange($event)"
               class="mat-elevation-z2"
               [ngClass]="paginatorClass">
</mat-paginator>

<app-alert *ngIf="rows.length === 0 && !isLoading"
           class="m-3"
           type="info"
           text="Nessun dato disponibile">
</app-alert>

<ng-template #detailLinkHeaderTmpl
             let-column="column">
</ng-template>

<ng-template #detailLinkCellTmpl
             let-row="row"
             let-value="value">
    <div class="w-100 d-flex justify-content-end">
        <ng-container *ngIf="!extraButtonsTmpl">
            <button mat-icon-button
                    class="mr-2"
                    (click)="deleteRow(row)"
                    *ngIf="_showDeleteBtn(row)"
                    title="{{'GRID.DELETE' | translate}}">
                <mat-icon>delete</mat-icon>
            </button>

            <button mat-icon-button
                    [routerLink]="[detailUrl(), row[trackBy]]"
                    *ngIf="showDetailLink"
                    title="{{'GRID.DETAIL' | translate}}">
                <mat-icon>arrow_forward_ios</mat-icon>
            </button>
        </ng-container>

        <ng-container *ngIf="extraButtonsTmpl">
            <button mat-icon-button
                    [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <ng-container *ngTemplateOutlet="extraButtonsTmpl; context: {row: row}"></ng-container>

                <button mat-menu-item
                        (click)="deleteRow(row)"
                        *ngIf="_showDeleteBtn(row)"
                        title="{{'GRID.DELETE' | translate}}">
                    <mat-icon class="mr-2">delete</mat-icon>
                    Elimina
                </button>

                <button mat-menu-item
                        [routerLink]="[detailUrl(), row[trackBy]]"
                        *ngIf="showDetailLink"
                        title="{{'GRID.DETAIL' | translate}}">
                    <mat-icon class="mr-2">navigate_next</mat-icon>
                    Dettagli
                </button>

            </mat-menu>
        </ng-container>
    </div>
</ng-template>
