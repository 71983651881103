import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

import {VersionResponse} from '../models/response/versionResponse';
import {BaseResponse} from '../models/response/baseResponse';
import {VersionRequest} from '../models/request/versionRequest';
import {ConfigService} from './config.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class VersionService {

    private apiLink: string;

    constructor(private httpClient: HttpClient,
                private configService: ConfigService) {
        /** ApiLink */
        this.apiLink = `${configService.config.apiEndpoint}/voucher`;
    }

    getVersionByVoucher(voucher_id: number): Observable<VersionResponse | undefined> {
        const url = `${this.apiLink}/by-voucher/${voucher_id}`;

        return this.httpClient.get<any>(url)
            .pipe(
                map(res => res ? new VersionResponse(res) : undefined)
            );
    }

    saveNewVersion(voucher_id: number, request: VersionRequest): Observable<BaseResponse> {
        const url = `${this.apiLink}/set-current`;
        request.voucherId = voucher_id;
        return this.httpClient.post<BaseResponse>(url, request);
    }
}
