import { Component, OnInit, forwardRef, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { NgForm, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { CertificateService } from 'app/armp/services/certificate.service';
import { CustomControl } from 'app/armp/models/custom-control';
import { UUID } from 'angular2-uuid';
import { AuthService } from 'app/armp/core/auth.service';

@Component({
  selector: 'app-certificate-management',
  templateUrl: './certificate-management.component.html',
  styleUrls: ['./certificate-management.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CertificateManagementComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CertificateManagementComponent),
      multi: true,
    },
  ],
})
export class CertificateManagementComponent extends CustomControl<Array<any>> implements OnInit {
  errorKey: string = 'certificates';

  _isReadonly = false;

  @Input()
  public set isReadonly(v: boolean) {
    this._isReadonly = v;
  }

  @Input()
  canManageCertificates = true;

  @Output()
  removed = new EventEmitter<any>();

  @ViewChild('form', { static: true }) form!: NgForm;

  certificateTypes = [];
  crts = {};

  constructor(private certificateService: CertificateService, private authService: AuthService) {
    super();
  }

  ngOnInit() {
    if (!this._isReadonly) {
      this.certificateService.getCertificateTypes().then((response) => {
        this.certificateTypes = response;
        this.certificateTypes.forEach((t) => {
          this.loadCertificates(t.id);
        });
      });
    }
  }

  addCertificate() {
    if (this.canAddCertificate) {
      this.value.push({});
      this.propagateOnChange(this.value);
      this.propagateOnTouched();
    }
  }

  removeCertificate(index: number) {
    const certificate = this.value[index];

    this.value.splice(index, 1);

    this.removed.emit(certificate);

    this.propagateOnChange(this.value);

    this.propagateOnTouched();
  }

  get certificates() {
    if (!this.value) {
      return [];
    }

    return this.value.map((x) => {
      if (!x.__cid) {
        x.__cid = UUID.UUID();
      }

      return x;
    });
  }

  isAlreadySelected(t) {
    return this.value.find((c) => {
      if (c.type === undefined) {
        return false;
      } else {
        return c.type === t.id;
      }
    });
  }

  public get canAddCertificate() {
    if (this.value === undefined) {
      return true;
    } else {
      return this.value.length < this.certificateTypes.length && Object.keys(this.crts).length > 0;
    }
  }

  loadCertificates(type: any) {
    if (type != undefined) {
      this.certificateService.getCertificatesByType(type).then((response) => {
        if (this.crts[type] == undefined || this.crts[type].length == 0) {
          this.crts[type] = response;
        }
        if (response.length === 0) {
          this.certificateTypes = this.certificateTypes.filter((x) => x.id !== type);
        }
      });
    }
  }
}
