import {Directive, Input} from '@angular/core';
import {FormValidators} from 'app/armp/shared/form-validators';
import {AbstractControl, NG_VALIDATORS, ValidationErrors} from '@angular/forms';
import {CustomValidatorDirective} from 'app/armp/models/custom-validator';

@Directive({
  selector: `[validateEmail][formControlName],[validateEmail][formControl],[validateEmail][ngModel]`,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EmailValidatorDirective,
      multi: true,
    },
  ],
})
export class EmailValidatorDirective extends CustomValidatorDirective {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('validateEmail') enabled: any;

  protected _validate(c: AbstractControl): ValidationErrors | null {
    return FormValidators.emailValidator(c.value);
  }
}
