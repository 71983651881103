import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorService {
  errors: Array<Error> = [];

  constructor(private router: Router, private translateService: TranslateService) {}

  handlePageNotAllowed() {
    this.handleGenericError(this.translateService.instant('ERROR.PAGE_NOT_ALLOWED'));
  }

  handleGenericError(message: string = '', path: string[] = ['error']) {
    this.errors = [];
    this.errors.push(new Error(message));
    this.router.navigate(path);
  }
}

export class Error {
  message: string;

  constructor(message: string) {
    this.message = message;
  }
}
