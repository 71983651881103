import { Directive, Input } from '@angular/core';
import { FormValidators } from 'app/armp/shared/form-validators';
import { CustomValidatorDirective } from 'app/armp/models/custom-validator';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
  selector: `[validateMaxLength][formControlName],[validateMaxLength][formControl],[validateMaxLength][ngModel]`,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MaxLengthDirective,
      multi: true,
    },
  ],
})
export class MaxLengthDirective extends CustomValidatorDirective {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('validateMaxLength') enabled: any;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('validateMaxLengthValue') maxLength: number;

  protected _validate(c: AbstractControl): ValidationErrors {
    return FormValidators.maxLengthValidator(c.value, this.maxLength);
  }
}
