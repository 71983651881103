<bitf-dnd-file (dropFiles)="onFileDropped($event)"
  *ngIf="showDndArea">
  <div class="col-auto d-flex justify-content-center align-items-center p-2">
    <span class="mat-caption mr-2">{{ 'BITF.FILE_UPLOADER.DROP_FILES_HERE_OR_SELECT' | translate }} </span>
    <button mat-stroked-button
      color="primary"
      (click)="selectFile.click()"> {{ 'BITF.FILE_UPLOADER.SELECT_FILE' | translate }}
    </button>
    <input class="d-none"
      type="file"
      #selectFile
      [attr.multiple]="(maxNumberOfFiles > 1) ? '' : null"
      (change)="onFileDropped($event)" />
  </div>
</bitf-dnd-file>

<div *ngIf="!showDndArea">
  <button mat-stroked-button
    color="primary"
    (click)="imgFileInput.click()">
    <mat-icon class="mr-2">cloud_upload</mat-icon> {{uploadButtonText | translate}}
  </button>
  <input type="file"
    class="d-none"
    #imgFileInput
    [attr.multiple]="(maxNumberOfFiles > 1) ? '' : null"
    (change)="onFileDropped($event)" />
</div>