import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CanActivateViaAuthGuard } from 'app/armp/core/routing/can-activate-via-auth';
import { AuthService } from 'app/armp/core/auth.service';
import { ErrorService } from 'app/armp/core/error.service';

@Injectable()
export class CanActivateAdmin {
  constructor(
    private authService: AuthService,
    private canActivateViaAuth: CanActivateViaAuthGuard,
    private errorService: ErrorService
  ) {}

  canActivate(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // NOTE: this: this.canActivateViaAuth.canActivate is an extra check since this route it's already 
    // protected by the main parent guard. this is a child guard.
    return this.canActivateViaAuth.canActivate(activatedRoute, state).then((auth) => {
      if (!auth) {
        return false;
      } else {
        if (this.authService.user.profile.name === 'ADMIN') {
          return true
        } else {
          this.errorService.handlePageNotAllowed();
          return false
        }
      }
    });
  }
}
