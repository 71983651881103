<div class="d-flex align-items-center justify-content-center w-100 my-4"
     *ngIf="dashboard">
    <button mat-button
            color="accent"
            (click)="refreshDashboard()">Aggiorna grafico
    </button>

    <app-pie-chart
            [dataTable]="chartData"
            [chartOptions]="chartOptions">
        <div centerContainer>
            <div class="chart-value">{{dashboard.total}}</div>
            <div class="mt-2">TOTALI</div>
        </div>
    </app-pie-chart>
</div>
