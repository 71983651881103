<app-page-header
        [title]="activatedRoute.snapshot.paramMap.get('id') ? 'Modifica certificato': 'Crea nuovo certificato'"
        [breadcrumb]="['Certificati']">

    <div class="d-flex">
        <button mat-stroked-button
                color="primary"
                class="mr-3"
                (click)="onBack()">
            Annulla
        </button>
        <button mat-raised-button
                [disabled]="!form.valid"
                color="primary"
                (click)="save()"
                *ngIf="authService.hasRole('CERTIFICATE_CAN_WRITE')">
            Salva
        </button>
    </div>

</app-page-header>

<div class="container-fluid py-3">
    <form #form="ngForm"
          novalidate>

        <div class="w-75 m-auto">
            <mat-card appearance="outlined">
                <mat-card-title class="d-flex align-items-center text-primary">
                    <mat-icon class="mr-2">description</mat-icon>
                    Dati Certificato
                </mat-card-title>
                <mat-card-content>

                    <mat-form-field appearance="standard"
                                    class="w-100">
                        <mat-label>Nome</mat-label>
                        <input matInput
                               type="text"
                               maxlength="255"
                               name="name"
                               placeholder="Inserisci nome certificato"
                               [(ngModel)]="certificate.name"
                               required
                               validateMaxLength
                               [validateMaxLengthValue]="255"
                               #nameModel="ngModel">
                        <mat-hint align="end">{{certificate?.name?.length || 0}}/255</mat-hint>
                        <mat-error appFormFieldError
                                   [formFieldModel]="nameModel"
                                   [form]="form"></mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="standard"
                                    class="w-100">
                        <mat-label>Password</mat-label>
                        <input matInput
                               type="password"
                               name="password"
                               placeholder="Inserisci password"
                               [(ngModel)]="certificate.passwordPlaintext"
                               validateMaxLength
                               [validateMaxLengthValue]="30"
                               compareSameValue
                               [compareSameValueWith]="form.controls['ripetiPassword']"
                               compareSameValueWithInputName="Ripeti password"
                               #passwordPlaintextModel="ngModel">
                        <mat-error appFormFieldError
                                   [formFieldModel]="passwordPlaintextModel"
                                   [form]="form"></mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="standard"
                                    class="w-100">
                        <mat-label>Ripeti password</mat-label>
                        <input matInput
                               type="password"
                               name="ripetiPassword"
                               placeholder="Ripeti password"
                               [(ngModel)]="ripetiPassword"
                               compareSameValue
                               [compareSameValueWith]="form.controls['password']"
                               compareSameValueWithInputName="Password"
                               #ripetiPasswordModel="ngModel">
                        <mat-error appFormFieldError
                                   [formFieldModel]="ripetiPasswordModel"
                                   [form]="form"></mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="standard"
                                    class="w-100">
                        <mat-label>Tipo di certificato</mat-label>
                        <mat-select [(ngModel)]="certificate.type"
                                    name="type"
                                    required>
                            <mat-option [value]="undefined">Seleziona</mat-option>
                            <mat-option *ngFor="let certificateType of certificateTypes"
                                        [value]="certificateType.id">{{certificateType.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="my-3">
                        <mat-checkbox name="defaultCertificate"
                                      [(ngModel)]="certificate.defaultCertificate">Default
                        </mat-checkbox>
                    </div>

                    <div class="my-3">
                        <mat-checkbox *ngIf="certificate.id"
                                      name="revoked"
                                      [(ngModel)]="certificate.revoked">Revocato
                        </mat-checkbox>
                    </div>

                    <span class="mb-2 mat-caption"
                          *ngIf="!certificate.id">Carica file certificato</span>
                    <span class="mb-2 mat-caption"
                          *ngIf="certificate.id">Sostituisci file certificato</span>
                    <app-file-uploader
                            [showFileList]="false"
                            [showDndArea]="true"
                            [maxNumberOfFiles]="1"
                            [showValidationErrorMessage]="true"
                            [concatenateMultipleFiles]="false"
                            [uploadOnFilesDropped]="true"
                            [uploadButtonText]="'Attiva istanza'"
                            (startUpload)="onStartUploadFile($event)"
                            class="flex-grow-1"></app-file-uploader>
                    <mat-error *ngIf="!certificate.id && !selectedFile && form.submitted">
                        <span class="help-block">{{'VALIDATION.REQUIRED' | translate}}</span>
                    </mat-error>

                    <button mat-raised-button
                            *ngIf="certificate.id"
                            class="mt-3"
                            color="accent"
                            (click)="downloadCertificate(certificate)">Download File Certificato
                    </button>

                </mat-card-content>
            </mat-card>
        </div>
    </form>
</div>
