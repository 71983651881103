// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment-proxy.json`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export let environment = {
    production: false
};

// Production
// export const environment = {
//   production: true,
//   apiEndpoint: '//api-private-armp.devops.aruba.it/api',
//   apiPublicEndpoint: '//api-public-armp.devops.aruba.it/api',
//   authEndpoint: '//auth-armp.devops.aruba.it',
//   oauth2: {
//     client: 'oauthclient',
//     secret: 'abcd1234',
//   },
//   logLevel: 4,
//   captcha: {
//     siteKey: '',
//   },
// };
