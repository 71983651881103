import { Injectable } from '@angular/core';

import { ApiPrivateService } from 'app/armp/crud/services';

@Injectable()
export class PolicyService extends ApiPrivateService {
  resourceName(): string {
    return 'policy';
  }

  getImplementations(scope?: string) {
    return this.httpHandler.httpGet('/implementation' + (scope ? '?scope=' + scope : ''));
  }

  getVoucherImplementations() {
    return this.httpHandler.httpGet('/implementation?scope=VOUCHER');
  }

  getLicenseInstanceImplementations() {
    return this.httpHandler.httpGet('/implementation?scope=LICENSE_INSTANCE');
  }

  getAvailablePolicyImplementations(
    policyImplementations: Array<any>,
    policies: Array<any>,
    policyIndex?: number
  ): Array<any> {
    return policyImplementations.filter((p) => {
      if (!p.multipleAllowed) {
        const index = policies.findIndex(
          (lp) => lp.implementation && lp.implementation.classname === p.classname
        );
        return index === -1 || index === policyIndex;
      }

      return true;
    });
  }
}
