import { ValidationErrors, AbstractControl, Validator } from '@angular/forms';
import { OnChanges, SimpleChanges, Directive } from '@angular/core';

@Directive()
export abstract class CustomValidatorDirective implements Validator, OnChanges {
  protected abstract enabled: boolean;

  protected _onChange!: () => void;

  protected _enabled?: boolean;

  validate(c: AbstractControl): ValidationErrors | null {
    if (this._enabled) {
      return this._validate(c);
    }

    return null;
  }

  protected abstract _validate(c: AbstractControl): ValidationErrors | null;

  registerOnValidatorChange(fn: () => void) {
    this._onChange = fn;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.enabled) {
      const value = changes.enabled.currentValue;
      this._enabled = value != null && value !== false && `${value}` !== 'false';
      if (this._onChange) {
        this._onChange();
      }
    }
  }
}
