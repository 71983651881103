import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { LicenseTemplateService } from 'app/armp/services/license-template.service';
import { AuthService } from 'app/armp/core/auth.service';
import { IDynamicTableColumnData } from 'common/interfaces/dynamic-column.interface';

@Component({
  selector: 'app-license-template-list',
  templateUrl: './license-template-list.component.html',
  styleUrls: ['./license-template-list.component.scss'],
})
export class LicenseTemplateListComponent implements OnInit {
  @ViewChild('dateTimeTpl', { static: true }) dateTimeTpl: ElementRef<any>;

  dataColumns: IDynamicTableColumnData[] = [];
  searchText: string;

  constructor(
    public licenseTemplateService: LicenseTemplateService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.dataColumns.push(
      {
        name: 'ID Licenza',
        prop: 'id',
        sortable: true,
      },
      {
        name: 'Nome',
        prop: 'name',
        sortable: true,
      },
      {
        name: 'Creata il',
        prop: 'createdOn',
        cellTemplate: this.dateTimeTpl,
        sortable: true,
      },
      {
        name: '# Policy',
        prop: 'totalPolicies',
        sortable: false,
        searchable: false,
      }
    );
  }
}
