import { Injectable } from '@angular/core';

import { ApiPrivateCrudService } from 'app/armp/crud/services';

@Injectable()
export class LicenseTemplateService extends ApiPrivateCrudService<any> {
  resourceName(): string {
    return 'license-template';
  }
}
