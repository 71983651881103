/**
 {
 "columns": [
 {
 "data": "displayName",
 "searchValue": null,
 "name": "displayName",
 "searchable": true,
 "orderable": true,
 "search": {
 }
 }
 ],
 "draw": 0,
 "length": 10,
 "order": [
 {
 "column": 0,
 "dir": "asc"
 }
 ],
 "search": {
 "value": "fuffa",
 "regex": true
 },
 "start": 0
 }
 */

export class DataTableColumn {
    data: string;
    searchValue: string;
    name: string;
    searchable = false;
    orderable = false;
    search: DataTableSearch = new DataTableSearch();
}

export class DataTableSearch {
    value: string;
    regex = true;
}

export class DataTableOrder {
    column = 0;
    dir: string = DataTableOrderDir.Asc;
}

export class DataTableOrderDir {
    static Asc = 'asc';
    static Desc = 'desc';
}

export class DataTableMultisearch {
    colname: string;
    regex: boolean;
    value: string;
}

export class DataTableRequest {
    draw = 0;
    length = 10;
    start = 0;
    columns: Array<DataTableColumn> = new Array();
    search: DataTableSearch = new DataTableSearch();
    order: Array<DataTableOrder> = new Array();
    multisearch?: DataTableMultisearch[];
}
