import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { LicenseTemplateService } from '../../services/license-template.service';
import { PolicyService } from 'app/armp/services/policy.service';
import { PolicyManagementComponent } from 'app/armp/policy/policy-management/policy-management/policy-management.component';
import { AuthService } from 'app/armp/core/auth.service';
import { LoaderService } from 'app/armp/core/loader.service';
import { ToastService } from 'app/armp/services/toast.service';

@Component({
  selector: 'app-license-template-create',
  templateUrl: './license-template-create.component.html',
  styleUrls: ['./license-template-create.component.scss'],
})
export class LicenseTemplateCreateComponent implements OnInit {
  @ViewChild('form', { static: true })
  private form: NgForm;

  @ViewChild(PolicyManagementComponent, { static: true })
  private policyManagement: PolicyManagementComponent;

  public licenseTemplate: any = {
    policies: [],
  };

  private policyImplementations = [];

  constructor(
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    private licenseTemplateService: LicenseTemplateService,
    private location: Location,
    private policyService: PolicyService,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.policyService.getImplementations().then((policyImplementations) => {
      this.policyImplementations = policyImplementations;
    });
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.licenseTemplateService.getById(params.id).then((response) => {
          this.licenseTemplate = response;
        });
      }
    });
  }

  onBack() {
    this.location.back();
  }

  save() {
    this.form.onSubmit(null);
    if (this.form.valid) {
      this.loaderService.show();
      this.licenseTemplateService
        .save(this.licenseTemplate)
        .then((x) => {
          this.loaderService.hide();
          this.toastService.success({
            message: this.translateService.instant('TOASTR.SAVE_SUCCESS'),
          });
          this.onBack();
        })
        .catch((x) => {
          this.loaderService.hide();
        });
    }
  }
}
