import {Version} from './version';

export class VersionList {
    classifier: string;
    selected?: Version;
    list: Version[];

    constructor(json: any) {
        this.classifier = json?.classifier ?? '';
        this.selected = json?.selected ? new Version(json?.selected, json.classifier) : undefined;
        this.list = json?.list.map(v => new Version(v, json.classifier)) ?? [];
    }
}
