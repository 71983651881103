import {Injectable} from '@angular/core';
import {ELoaderActionEnum} from 'common/enums/loader.enum';
import {Subject} from 'rxjs';

const DELAY = 600;
const MIN_TIMEOUT = 5000;

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    onLoaderChange: Subject<any> = new Subject<any>();
    isLoading: boolean;
    timeoutId: any;
    hideTimeout: number;
    hideTimeoutId: any;
    showCallsCounter = 0;

    constructor() {
        this.hideTimeout = DELAY + MIN_TIMEOUT;
    }

    show(data?: any) {
        this.clearTimeouts();
        this.showCallsCounter += 1;
        this.timeoutId = setTimeout(() => {
            this.onLoaderChange.next({action: ELoaderActionEnum.SHOW, data});
        }, DELAY);
        if (!data || (data && !data.disableHideLoader)) {
            this.hideTimeoutId = setTimeout(() => {
                this.showCallsCounter = 0;
                this.hide();
            }, this.hideTimeout);
        }
    }

    hide() {
        this.showCallsCounter -= 1;
        if (this.showCallsCounter <= 0) {
            this.forceHide();
        }
    }

    forceHide() {
        this.clearTimeouts();
        this.onLoaderChange.next({action: ELoaderActionEnum.HIDE});
        this.showCallsCounter = 0;
    }

    private clearTimeouts() {
        clearTimeout(this.timeoutId);
        clearTimeout(this.hideTimeoutId);
    }
}
