<div class="row">

  <div class="col-24 col-sm-6">

    <div class="d-flex flex-column">
      <bitf-search (valueChanges)="licenseInstanceSearchText = $event"
        placeholder="Cerca istanza"
        label="Cerca istanza"
        [hasSearchIcon]="true"
        formFieldClass="w-100"
        appearance="fill">
      </bitf-search>

      <!-- Totale Istanze -->
      <h4 class="font-weight-bold mt-2 mb-1">Totale Istanze</h4>
      <p>{{licenseInstancePage?.totalElements}}</p>

      <!-- Attive -->
      <h4 class="font-weight-bold mt-2 pt-2 mb-1 border-top">Attive</h4>
      <p>{{licenseInstancePage?.totalElements}}</p>

      <!-- Riassegnabili -->
      <h4
        class="font-weight-bold mt-2 pt-2 mb-1 border-top d-flex justify-content-between align-items-center">
        <span> Riassegnabili </span>
        <div>
          <button mat-icon-button
            type="button"
            (click)="onEditMaxReassignments()"
            *ngIf="!maxReassignmentsEdit && !isReadonly && authService.hasRole('VOUCHER_CAN_WRITE')">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button
            type="button"
            *ngIf="maxReassignmentsEdit && !isReadonly"
            (click)="saveMaxReassignments()"
            [disabled]="maxReassignmentsForm.invalid">
            <mat-icon>check</mat-icon>
          </button>
          <button mat-icon-button
            (click)="undoMaxReassignments()"
            *ngIf="maxReassignmentsEdit">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
      </h4>
      <p>
        {{voucher?.maxReassignments - voucher?.reassignments}}
        -
        (max: {{voucher?.maxReassignments}})
      </p>
      <div>
        <form #maxReassignmentsForm="ngForm">
          <div class="d-flex align-items-center"
            *ngIf="maxReassignmentsEdit">
            <mat-form-field appearance="standard"
              class="w-100">
              <mat-label>Max</mat-label>
              <input matInput
                [(ngModel)]="voucher.maxReassignments"
                #maxReassignmentsModel="ngModel"
                name="maxReassignments"
                placeholder="Max"
                [min]="voucher.reassignments"
                [max]="99999"
                required
                validateMin
                [validateMinValue]="1"
                validateMax
                [validateMaxValue]="99999">
              <mat-error appFormFieldError
                [formFieldModel]="maxReassignmentsModel"
                [form]="maxReassignmentsForm"></mat-error>
            </mat-form-field>
          </div>
        </form>
      </div>

      <!-- Modalità Scadenza -->
      <h4 class="font-weight-bold mt-2 pt-2 mb-1 border-top">Modalità Scadenza</h4>
      <p>
        <span [hidden]="voucher?.licenseExpirationMode!=='VOUCHER_EXPIRATION'">Scadenza Voucher</span>
        <span [hidden]="voucher?.licenseExpirationMode==='VOUCHER_EXPIRATION'">Validità
          ({{voucher.licenseInstanceValidity}} giorni)</span>
      </p>
    </div>

  </div>

  <div class="col-24 col-sm-18">
    <div *ngIf="canAddLicenseInstance() || true"
      class="d-flex align-items-center justify-content-end">
      <span class="mr-2 mat-caption">Attiva istanza:</span>
      <app-file-uploader
        [showFileList]="false"
        [showDndArea]="true"
        [maxNumberOfFiles]="1"
        [extensions]="['.bin']"
        [showValidationErrorMessage]="true"
        [concatenateMultipleFiles]="false"
        [uploadOnFilesDropped]="true"
        [uploadButtonText]="'Attiva istanza'"
        (startUpload)="onStartUploadMachineId($event)"></app-file-uploader>

      <button mat-stroked-button
        color="primary"
        class="ml-3"
        (click)="refresh()">
        <mat-icon class="mr-2">refresh</mat-icon>
        Aggiorna
      </button>
    </div>

    <app-dynamic-table #licenseInstancesTable
      tableClass="shadow-none"
      paginatorClass="shadow-none"
      (fetchData)="fetchLicenseInstances($event)"
      (dataFetched)="licenseInstancesFetched($event)"
      [sorts]="[{prop: 'createdOn', dir: 'desc'}]"
      [detailRoute]="'/license-instance'"
      [columns]="licenseInstanceCols"
      [showDetailLink]="!isReadonly"
      [showDeleteBtn]="false"
      [searchText]="licenseInstanceSearchText"
      [extraButtons]="isReadonly ? 2 : 1">

      <ng-template #licenseInstanceActiveTmpl
        let-value="value"
        let-row="row">
        <mat-chip class="chip-small text-white"
          [ngClass]="{'bg-success': value, 'bg-error': !value }"
          [disableRipple]="true"
          [selectable]="false">
          <mat-icon *ngIf="value"
            matTooltip="Attiva">check</mat-icon>
          <mat-icon *ngIf="!value"
            matTooltip="Non attiva">close</mat-icon>
        </mat-chip>
      </ng-template>

      <ng-template #dateTimeTpl
        let-value="value">
        {{value | date: 'medium'}}
      </ng-template>

      <ng-template #extraButtonsTmpl
        let-row="row">
        <button mat-menu-item
          *ngIf="isReadonly && row.active"
          (click)="reassign(row)">
          <mat-icon class="mr-2">keyboard_tab</mat-icon>
          Riassegna
        </button>
        <button mat-menu-item
          *ngIf="row.machineIdFileExists"
          (click)="downloadLicense(row)">
          <mat-icon class="mr-2">file_download</mat-icon>
          Scarica licenza
        </button>
      </ng-template>

    </app-dynamic-table>

  </div>
</div>
