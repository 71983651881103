<div #form="ngForm"
  ngForm="form">
  <mat-chip color="accent"
    selected
    [hidden]="value">Nessuna policy aggiunta</mat-chip>

  <mat-card appearance="outlined" class="mb-4 mat-sub-card"
    *ngFor="let policy of policies; let policyIndex = index;"
    ngModelGroup="{{ policy.__cid }}">
    <mat-card-title-group>
      <mat-card-title>
        Policy {{policy.id ? policy.name : 'Aggiuntiva '}}
      </mat-card-title>

      <mat-card-subtitle *ngIf="policy.id">
        {{policy.implementation.name}}
      </mat-card-subtitle>
    </mat-card-title-group>

    <mat-card-content>

      <mat-form-field appearance="standard"
        *ngIf="!policy.id"
        class="w-100">
        <mat-label>Tipo:</mat-label>
        <mat-select name="code"
          [(ngModel)]="policy.implementation"
          #codeModel="ngModel"
          [disabled]="disabled"
          required
          (selectionChange)="onImplementationChange(policy)">
          <mat-option [value]="null"
            selected>Seleziona il tipo</mat-option>
          <mat-option *ngFor="let policyImpl of getAvailablePolicyImplementations(policyIndex)"
            [value]="policyImpl">
            {{policyImpl.name}}</mat-option>
        </mat-select>
        <mat-error appFormFieldError
          [formFieldModel]="codeModel"
          [form]="actualForm"></mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard"
        class="w-100">
        <mat-label>Nome</mat-label>
        <input matInput
          [max]="255"
          name="name"
          [(ngModel)]="policy.name"
          #nameModel="ngModel"
          [disabled]="disabled"
          required>
        <mat-error appFormFieldError
          [formFieldModel]="nameModel"
          [form]="actualForm"></mat-error>
      </mat-form-field>

      <div *ngIf="policy.implementation"
        class="mt-3">
        <h3 class="m-0">Configurazione:</h3>
        <app-policy-cfg name="configuration"
          [(ngModel)]="value[policyIndex]"
          [topForm]="actualForm">
        </app-policy-cfg>
      </div>

    </mat-card-content>

    <mat-card-actions align="end">
      <button mat-icon-button
        type="button"
        (click)="removePolicy(policyIndex)">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>

  <mat-divider *ngIf="policies.length"></mat-divider>

  <div class="d-flex align-items-center pt-3 justify-content-end">
    <button mat-raised-button
      type="button"
      color="primary"
      (click)="addPolicy()"
      [disabled]="!canAddPolicy">
      Aggiungi policy
    </button>
  </div>
</div>