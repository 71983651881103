import { Page } from '../models/page';
import { DataTableRequest } from '../models/datatable-request';
import {Product} from '../models/product';
import { Injectable } from '@angular/core';

import { ApiPrivateCrudService } from 'app/armp/crud/services';

@Injectable()
export class ProductService extends ApiPrivateCrudService<Product> {
  resourceName(): string {
    return 'product';
  }

  getGridByCustomer(dataTableRequest: DataTableRequest, customerId: number) {
    return this.httpHandler.httpPost('/grid-by-customer?customerId=' + customerId, dataTableRequest);
  }
}
