import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CONSTANTS } from 'common/constants/constants';
import { GoogleChartInterface } from 'ng2-google-charts';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnInit, OnChanges {
  @Input() dataTable: any;
  @Input() chartOptions: GoogleChartInterface['options'];

  dataChart!: GoogleChartInterface;
  centerPositionX!: number;

  @ViewChild('chart', { static: false }) chart: any;

  constructor() {}

  ngOnInit() {
    fromEvent(window, 'resize')
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.loadChart();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.dataTable?.currentValue &&
      changes.dataTable?.currentValue !== changes.dataTable?.previousValue
    ) {
      this.loadChart();
    }
  }

  private loadChart() {
    this.dataChart = {
      chartType: 'PieChart',
      dataTable: this.dataTable,
      options: {
        pieHole: 0.5,
        pieSliceText: 'none',
        backgroundColor: 'transparent',
        tooltip: { trigger: 'none' },
        colors: [
          CONSTANTS.COLORS.darkRed,
          CONSTANTS.COLORS.orange,
          CONSTANTS.COLORS.green,
          CONSTANTS.COLORS.grey,
        ],
        sliceVisibilityThreshold: 0,
        chartArea: { width: '100%', height: '100%' },
        legend: {
          position: 'right',
          alignment: 'center',
          textStyle: {
            color: '#00000',
            fontName: 'Lato',
            fontSize: '14px',
            bold: false,
            italic: 'false',
          },
        },
        enableInteractivity: false,
        ...this.chartOptions,
      },
    };

    this.chart?.draw(this.dataChart);
  }

  chartIsReady() {
    let start = 1000000;
    let end = 0;

    const elements: any[] = this.chart.el.nativeElement.querySelectorAll('svg > g');
    if (this.dataChart.options.legend.position === 'right') {
      elements.forEach((element, index) => {
        if (index === 0 || index === elements.length - 1) {
          return;
        }
        [start, end] = this.calculatePosition(start, end, element.getBBox().x, element.getBBox().width);
      });
    } else if (this.dataChart.options.legend === 'none') {
      elements.forEach((element, index) => {
        if (index === elements.length - 1) {
          return;
        }
        [start, end] = this.calculatePosition(start, end, element.getBBox().x, element.getBBox().width);
      });
    }
    this.centerPositionX = (end + start) / 2;
  }

  private calculatePosition(start: number, end: number, currentX: number, currentWidth: number): [number, number] {
    if (currentX < start) {
      start = currentX;
    }
    const currendEnd = currentX + currentWidth;
    if (currendEnd > end) {
      end = currendEnd;
    }
    return [start, end];
  }
}
