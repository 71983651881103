import { Component, OnInit, Input, Injector, Output } from '@angular/core';
import { Observable } from 'rxjs';

// tslint:disable-next-line:max-line-length
import { BitfFileUploaderComponent } from 'common/libs/bitforce/core/components/ui/file-uploader/bitf-file-uploader.component';
import { BitfFile } from 'common/libs/bitforce/models';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent extends BitfFileUploaderComponent<BitfFile> implements OnInit {
  @Input() uploadButtonText: string;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  upload() {
    this.startUpload.emit(this.files && this.files[0]);
  }
}
