<h1 mat-dialog-title>Nuova Policy</h1>

<form #form="ngForm"
  mat-dialog-content>

  <div class="d-flex flex-column">

    <mat-form-field appearance="standard">
      <mat-label>Tipo</mat-label>
      <mat-select [(ngModel)]="policy.implementation"
        #implementationModel="ngModel"
        required
        name="implementation">
        <mat-option [value]="undefined"
          selected>Seleziona il tipo</mat-option>
        <mat-option *ngFor="let policyImplementation of availablePolicyImplementations"
          [value]="policyImplementation">{{policyImplementation.name}}</mat-option>
      </mat-select>
      <mat-error appFormFieldError
        [formFieldModel]="implementationModel"
        [form]="form"></mat-error>
    </mat-form-field>

    <mat-form-field appearance="standard">
      <mat-label>Nome</mat-label>
      <input matInput
        placeholder="Nome"
        maxlength="255"
        name="name"
        [(ngModel)]="policy.name"
        #nameModel="ngModel"
        required>
      <mat-error appFormFieldError
        [formFieldModel]="nameModel"
        [form]="form"></mat-error>
    </mat-form-field>

    <div *ngIf="policy.implementation"
      class="mt-3">
      <h3 class="m-0">Configurazione:</h3>
      <app-policy-cfg name="policy"
        [(ngModel)]="policy"
        [topForm]="form"></app-policy-cfg>
    </div>

  </div>

</form>

<div mat-dialog-actions
  [align]="'end'">

  <button mat-button
    type="button"
    mat-dialog-close>Chiudi</button>

  <button mat-raised-button
    color="primary"
    type="button"
    [disabled]="form.invalid"
    (click)="onConfirm()">Salva</button>

</div>