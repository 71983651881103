<app-page-header
        [title]="'ID ' + voucher.code"
        [breadcrumb]="['Voucher']"
        [showBack]="true"
        [backUrl]="['/public']">
    <div class="d-flex">
        <button mat-stroked-button
                color="primary"
                (click)="refreshVoucher()">
            Aggiorna
        </button>
    </div>
</app-page-header>

<div class="container-fluid py-3">

    <div class="row">

        <div class="col-24">
            <app-voucher-status
                    [voucher]="voucher"
                    [isReadonly]="true"
                    [useToken]="true"
                    #voucherStatus>
            </app-voucher-status>
        </div>

    </div>

    <div class="row mt-4">

        <div class="col-24">
            <mat-card appearance="outlined">
                <mat-card-title class="text-primary d-flex align-items-center justify-content-between">
          <span class="d-flex align-items-center">
            <mat-icon class="mr-2">computer</mat-icon> Istanze Associate
          </span>
                </mat-card-title>
                <mat-card-content>
                    <div class="mt-2">
                        <app-voucher-license-instances [voucher]="voucher"
                                                       [isReadonly]="true"
                                                       [useToken]="true"
                                                       #licenseInstances>
                        </app-voucher-license-instances>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

    </div>

</div>
