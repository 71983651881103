import { Injectable } from '@angular/core';
import { ApiPrivateCrudService } from 'app/armp/crud/services';

@Injectable()
export class AutomaticUserBindingService extends ApiPrivateCrudService<object> {
  resourceName(): string {
    return 'automatic-signature-user';
  }

  addUser(automaticUser: any) {
    return this.httpHandler.httpPost('/add-signature', automaticUser);
  }

  updateSecure(automaticUser: any) {
    return this.httpHandler.httpPut('/update_secure/' + automaticUser.id, automaticUser);
  }

}
