import { BitfMatOkCancelDialogComponent } from 'common/libs/bitforce/core/components/ui/bitf-mat-ok-cancel-dialog/bitf-mat-ok-cancel-dialog.component';

export const CONSTANTS = {
  COLORS: {
    red: '#f44336',
    darkRed: '#b71c1c',
    green: '#4caf50',
    orange: '#ff9800',
    grey: '#9e9e9e',
    lightGrey: '#fafafa',
    lightBlue: '#80D8FF',
  },
  okCancelDialogComponent: BitfMatOkCancelDialogComponent,
};
