<app-page-header title="Voucher"
                 [showBack]="false">

    <div class="d-flex align-items-center">
        <button class="mr-4"
                mat-raised-button
                color="primary"
                [routerLink]="['new']"
                *ngIf="authService.hasRole('VOUCHER_CAN_WRITE')">
            Crea voucher
        </button>

        <bitf-search (valueChanges)="searchText = $event"
                     placeholder="Codice / Cliente / Prodotto"
                     label="Cerca voucher"
                     [isInToolbar]="true"
                     [hasSearchIcon]="false">
        </bitf-search>
    </div>

</app-page-header>

<div class="container-fluid py-3">
    <app-vouchers-dashboard class="mb-4"
                            [searchText]="searchText">
    </app-vouchers-dashboard>

    <app-dynamic-table
            [service]="voucherService"
            [columns]="dataColumns"
            [sorts]="[{prop: 'createdOn', dir:'desc'}]"
            [trackBy]="'code'"
            [showDeleteBtn]="authService.hasRole('VOUCHER_CAN_DELETE')"
            [searchText]="searchText">

        <ng-template #statusColTpl
                     let-row="row"
                     let-value="value">
            <ng-template #voucherDetailStatusTpl>
                <mat-list role="list"
                          class="p-0">
                    <mat-list-item role="listitem"
                                   *ngFor="let detail of row.voucherStatusDetail">
                        <mat-icon class="mr-2 text-warning"
                                  *ngIf="detail.status === 'WARNING'">warning
                        </mat-icon>
                        <mat-icon class="mr-2 text-error-dark"
                                  *ngIf="detail.status !== 'WARNING'">error
                        </mat-icon>
                        {{detail.message}}
                    </mat-list-item>
                </mat-list>
            </ng-template>

            <div [ngSwitch]="value"
                 class="text-center">
                <ng-container *ngSwitchCase="'VALID'">
                    <mat-chip class="chip-small bg-success text-white"
                              [disableRipple]="true"
                              [selectable]="false">
                        VALIDO
                    </mat-chip>
                </ng-container>

                <ng-container *ngSwitchCase="'WARNING'">
                    <mat-chip class="chip-small bg-warning text-white"
                              [disableRipple]="true"
                              [selectable]="false"
                              bitfTooltip
                              [tooltipTemplate]="voucherDetailStatusTpl"
                              [offsetX]="10">
                        ATTENZIONE
                    </mat-chip>
                </ng-container>

                <ng-container *ngSwitchDefault>
                    <mat-chip class="chip-small bg-error-dark text-white"
                              [disableRipple]="true"
                              [selectable]="false"
                              bitfTooltip
                              [tooltipTemplate]="voucherDetailStatusTpl"
                              [offsetX]="10">
                        NON VALIDO
                    </mat-chip>
                </ng-container>
            </div>
        </ng-template>

        <ng-template #voucherActiveTmpl
                     let-value="value"
                     let-row="row">
            <div class="text-center">
                <mat-chip class="chip-small text-white"
                          [ngClass]="{'bg-success': value, 'bg-error': !value}"
                          [disableRipple]="true"
                          [selectable]="false">
                    {{value ? 'Attivo' : 'Non Attivo'}}</mat-chip>
            </div>
        </ng-template>

        <ng-template #dateTimeTpl
                     let-value="value">
            {{value | date: 'short'}}
        </ng-template>

        <ng-template #dateTpl
                     let-value="value">
            {{value | date: 'shortDate'}}
        </ng-template>
    </app-dynamic-table>
</div>
