<app-page-header
  [title]="activatedRoute.snapshot.paramMap.get('id') ? 'Modifica sistema': 'Crea nuovo sistema'"
  [breadcrumb]="['Sistemi']">

  <div class="d-flex">
    <button mat-stroked-button
      color="primary"
      class="mr-3"
      (click)="onBack()">
      Annulla
    </button>
    <button mat-raised-button
      [disabled]="!form.valid"
      color="primary"
      (click)="save()"
      *ngIf="authService.hasRole('SYSTEM_CAN_WRITE')">
      Salva
    </button>
  </div>

</app-page-header>


<div class="container-fluid py-3">
  <form #form="ngForm"
    novalidate>

    <div class="w-75 m-auto">
      <mat-card appearance="outlined">
        <mat-card-title class="d-flex align-items-center text-primary">
          <mat-icon class="mr-2">description</mat-icon>
          Definizione sistema
        </mat-card-title>
        <mat-card-content>

          <mat-form-field appearance="standard"
            class="w-100">
            <mat-label>Nome</mat-label>
            <input matInput
              type="text"
              maxlength="255"
              name="name"
              placeholder="Inserisci nome"
              [(ngModel)]="entity.name"
              required
              #nameModel="ngModel">
            <mat-hint align="end">{{entity?.name?.length || 0}}/255</mat-hint>

            <mat-error appFormFieldError
              [formFieldModel]="nameModel"
              [form]="form"></mat-error>
          </mat-form-field>

          <mat-form-field appearance="standard"
            class="w-100">
            <mat-label>Template url</mat-label>
            <input matInput
              type="text"
              maxlength="255"
              name="urlTemplate"
              placeholder="Inserisci template url"
              [(ngModel)]="entity.urlTemplate"
              required
              #urlTemplateModel="ngModel">
            <mat-hint align="end">{{entity?.urlTemplate?.length || 0}}/255</mat-hint>

            <mat-error appFormFieldError
              [formFieldModel]="urlTemplateModel"
              [form]="form"></mat-error>
          </mat-form-field>

          <mat-form-field appearance="standard"
            class="w-100">
            <mat-label>Template url Ordine</mat-label>
            <input matInput
              type="text"
              maxlength="255"
              name="orderUrlTemplate"
              placeholder="Inserisci template url ordine"
              [(ngModel)]="entity.orderUrlTemplate"
              #orderUrlTemplateModel="ngModel">
            <mat-hint align="end">{{entity?.orderUrlTemplate?.length || 0}}/255</mat-hint>

            <mat-error appFormFieldError
              [formFieldModel]="orderUrlTemplateModel"
              [form]="form"></mat-error>
          </mat-form-field>

        </mat-card-content>
      </mat-card>
    </div>

  </form>
</div>