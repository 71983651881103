<mat-form-field [appearance]="appearance"
  [ngClass]="{'form-field-without-padding': isInToolbar}"
  class="{{formFieldClass}}">
  <mat-label>{{label || placeholder}}</mat-label>
  <input matInput
    [formControl]="formControl"
    [placeholder]="placeholder">
  <mat-icon matSuffix
    *ngIf="hasSearchIcon">search</mat-icon>

  <button mat-button
    mat-icon-button
    matSuffix
    (click)="clearSearchField()"
    *ngIf="hasCancelButton && formControl.value">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>