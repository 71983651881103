import { Injectable } from '@angular/core';
import { ApiPublicCruService } from 'app/armp/crud/services';

@Injectable()
export class CustomerPublicService extends ApiPublicCruService<any> {
  resourceName(): string {
    return 'customer';
  }

  getExternalLink(id: number) {
    return this.httpHandler.httpGet(`/${id}/external-link`);
  }
}
