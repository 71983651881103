import { Directive, Input } from '@angular/core';
import { CustomValidatorDirective } from 'app/armp/models/custom-validator';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';
import { FormValidators } from 'app/armp/shared/form-validators';
@Directive({
  selector: `[validateVatCode][formControlName],[validateVatCode][formControl],[validateVatCode][ngModel]`,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: VatCodeDirective,
      multi: true,
    },
  ],
})
export class VatCodeDirective extends CustomValidatorDirective {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('validateVatCode') enabled: any;

  protected _validate(c: AbstractControl): ValidationErrors {
    return FormValidators.vatCodeValidator(c.value);
  }
}
