import {ChangeDetectorRef, Component, Input, OnInit,} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';

import {TranslateService} from '@ngx-translate/core';
import {UUID} from 'angular2-uuid';

import {PolicyService} from 'app/armp/services/policy.service';
import {PolicyInstanceService} from 'app/armp/services/policy-instance.service';
import {PolicyEditModalComponent} from 'app/armp/policy/policy-edit-modal/policy-edit-modal.component';
import {ToastService} from 'app/armp/services/toast.service';

@Component({
  selector: 'app-license-instance-policy',
  templateUrl: './license-instance-policy.component.html',
  styleUrls: ['./license-instance-policy.component.scss'],
})
export class LicenseInstancePolicyComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() licenseInstance;
  @Input() voucher;

  policyImplementations: any;
  displayedColumns = ['nome', 'tipo', 'indicatori', 'actions'];

  private policyEditing = {};
  private newPolicy = {};

  constructor(
    private policyService: PolicyService,
    private policyInstanceService: PolicyInstanceService,
    private translator: TranslateService,
    private dialog: MatDialog,
    private toastService: ToastService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.policyService
      .getImplementations('LICENSE_INSTANCE')
      .then((x) => (this.policyImplementations = x));
  }

  ngOnInit() {}

  addPolicy() {
    this.dialog
      .open(PolicyEditModalComponent, {
        width: '400px',
        maxWidth: '100%',
        data: {},
      })
      .afterClosed()
      .subscribe((policy: any) => {
        if (policy) {
          policy.licenseInstanceId = this.licenseInstance.id;
          this.saveNewPolicy(policy);
        }
      });
  }

  removePolicy(policy) {
    if (policy.voucherId) {
      const removedPolicy = {
        ...policy,
        id: null,
        licenseInstanceId: this.licenseInstance.id,
        overwriteMode: 'REMOVE',
      };

      this.policyInstanceService.save(removedPolicy).then((p) => {
        this.licenseInstance.policies.push(p);
      });
    } else {
      if (policy.id) {
        this.policyInstanceService.delete(policy.id).then(() => {
          this.licenseInstance.policies.splice(
            this.licenseInstance.policies.findIndex(
              (x) => x.implementation.name === policy.implementation.name
            ),
            1
          );
        });
      } else {
        this.licenseInstance.policies.splice(
          this.licenseInstance.policies.findIndex(
            (x) => x.implementation.name === policy.implementation.name
          ),
          1
        );
      }
    }
  }

  recoverPolicy(policy) {
    const instancePolicy = this.licenseInstance.policies.find(
      (p) => p.implementation.name === policy.implementation.name
    );

    this.removePolicy(instancePolicy);
  }

  startPolicyEditing(policy, overwriteMode) {
    if (policy.voucherId) {
      const overwritingPolicy = { ...policy };

      overwritingPolicy.id = null;
      overwritingPolicy.overwriteMode = overwriteMode;
      overwritingPolicy.licenseInstanceId = this.licenseInstance.id;
      overwritingPolicy.actual = 0;
      overwritingPolicy.$$cid = UUID.UUID();
      delete overwritingPolicy.voucherId;
      this.licenseInstance.policies.push(overwritingPolicy);

      this.policyEditing[overwritingPolicy.$$cid] = true;
      //this.policyEditing[this.policies.length - 1] = true;
    } else {
      this.policyEditing[policy.$$cid] = true;
    }
  }

  stopPolicyEditing(policy, form: NgForm) {
    form.onSubmit(null);
    if (form.valid) {
      this.policyInstanceService.save(policy).then((p) => {
        this.policyEditing[policy.$$cid] = false;
        const i = this.licenseInstance.policies.findIndex(
          (x) => x.implementation.code === p.implementation.code
        );
        this.licenseInstance.policies[i] = p;
      });
    }
  }

  getPolicyRowClass(row) {
    if (row.voucherId) {
      return ' policy-voucher-row overwrite-mode-' + (row.overwriteMode || '');
    }
  }

  get policies() {
    let list = [];

    if (this.voucher && this.licenseInstance) {
      list = list
        .concat(
          this.voucher.policies.filter(
            (p) => p.implementation.scope !== 'VOUCHER'
          ),
          this.licenseInstance.policies
        )
        .map((p) => {
          p.deletable = true;
          p.editable = true;
          p.recoverable = false;
          p.fromVoucher = false;
          if (!p.$$cid) {
            p.$$cid = UUID.UUID();
          }

          if (p.voucherId) {
            const instancePolicy = this.licenseInstance.policies.find(
              (x) => x.name === p.name
            );
            if (instancePolicy) {
              p.overwriteMode = instancePolicy.overwriteMode;
              p.editable = false;
              p.deletable = false;
            } else {
              p.overwriteMode = null;
              p.deletable = true;
              p.editable = true;
            }

            if (p.overwriteMode === 'REMOVE') {
              p.recoverable = true;
            }
          } else {
            const voucherPolicy = this.voucher.policies.find(
              (x) => x.name === p.name
            );
            if (voucherPolicy) {
              p.fromVoucher = true;
            }
          }
          return p;
        })
        .filter((p) => {
          if (!p.voucherId && p.overwriteMode === 'REMOVE') {
            return false;
          }
          if (p.implementation.scope === 'VOUCHER') {
            return false;
          }
          return true;
        })
        .sort((x, y) => {
          if (x.voucherId && y.voucherId) {
            if (x.overwriteMode === y.overwriteMode) {
              return 0;
            }

            if (x.overwriteMode && !y.overwriteMode) {
              return -1;
            }

            if (!x.overwriteMode && y.overwriteMode) {
              return 1;
            }

            if (x.overwriteMode && y.overwriteMode) {
              if (x.overwriteMode === 'REMOVE') {
                return -1;
              }
            }

            return x.id - y.id;
          }

          if (x.licenseInstanceId) {
            return x.licenseInstanceId - y.licenseInstanceId;
          }
        });
    }

    return list;
  }

  saveNewPolicy(policy: any) {
    this.policyInstanceService.save(policy).then((p) => {
      this.toastService.success({
        message: this.translator.instant('TOASTR.SAVE_SUCCESS'),
      });
      this.licenseInstance.policies.push(p);
      this.newPolicy = {};
      this.changeDetectorRef.detectChanges();
    });
  }

  canAddPolicy() {
    if (this.disabled) {
      return false;
    }
    if (this.policyImplementations) {
      return this.availablePolicyImplementations.length > 0;
    }
    return false;
  }

  canEditPolicyName(policy) {
    if (!this.isEditingPolicy(policy)) {
      return false;
    }

    if (policy.fromVoucher) {
      return false;
    }

    return true;
  }

  isEditingPolicy(policy): boolean {
    return this.policyEditing[policy.$$cid];
  }

  get availablePolicyImplementations(): Array<any> {
    return this.policyImplementations;
  }
}
