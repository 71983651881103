import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {TranslateModule} from '@ngx-translate/core';

import {VouchersDashboardComponent} from './vouchers-dashboard/vouchers-dashboard.component';
import {SharedModule} from '../shared/shared.module';
import {VoucherPublicHomeComponent} from './voucher-public-home/voucher-public-home.component';
import {VoucherPublicEditComponent} from './voucher-public-edit/voucher-public-edit.component';
import {VoucherPoliciesComponent} from './voucher-policies/voucher-policies.component';
import {VoucherListComponent} from './voucher-list/voucher-list.component';
import {VoucherLicenseInstancesComponent} from './voucher-license-instances/voucher-license-instances.component';
import {VoucherEditComponent} from './voucher-edit/voucher-edit.component';
import {VoucherStatusComponent} from './voucher-edit/voucher-status/voucher-status/voucher-status.component';
import {VoucherCreateComponent} from './voucher-create/voucher-create.component';
import {
    PolicyExpirationStatusComponent
} from './policy-status/policy-expiration-status/policy-expiration-status.component';
import {
    PolicyBaseConsumptionStatusComponent
} from './policy-status/policy-base-consumption-status/policy-base-consumption-status.component';
import {PolicyModule} from '../policy/policy.module';
import {DynamicTableModule} from '../crud/dynamic-table/dynamic-table.module';
import {RouterLink} from '@angular/router';
import {CertificateModule} from '../certificate/certificate.module';
import {VoucherVersionComponent} from './voucher-edit/voucher-version/voucher-version.component';
import {FormValidatorModule} from '../shared/form-validator/form-validator.module';


@NgModule({
    declarations: [
        VouchersDashboardComponent,
        VoucherPublicHomeComponent,
        VoucherPublicEditComponent,
        VoucherPoliciesComponent,
        VoucherListComponent,
        VoucherLicenseInstancesComponent,
        VoucherEditComponent,
        VoucherStatusComponent,
        VoucherCreateComponent,
        PolicyExpirationStatusComponent,
        PolicyBaseConsumptionStatusComponent,
        VoucherVersionComponent,

    ],
    imports: [
        CommonModule,
        SharedModule,
        PolicyModule,
        DynamicTableModule,
        RouterLink,
        CertificateModule,
        TranslateModule.forChild(),
        FormValidatorModule
    ],
    exports: [
        VouchersDashboardComponent,
        VoucherPublicHomeComponent,
        VoucherPublicEditComponent,
        VoucherPoliciesComponent,
        VoucherListComponent,
        VoucherLicenseInstancesComponent,
        VoucherEditComponent,
        VoucherStatusComponent,
        VoucherCreateComponent,
        PolicyExpirationStatusComponent,
        PolicyBaseConsumptionStatusComponent,

    ]
})
export class VoucherModule {
}
