import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { DataTableRequest } from 'app/armp/models/datatable-request';

import { ApiPrivateCruService } from 'app/armp/crud/services';

@Injectable()
export class LicenseInstanceService extends ApiPrivateCruService<object> {
  resourceName(): string {
    return 'license-instance';
  }

  getGridByVoucher(
    dataTableRequest: DataTableRequest,
    voucherCode: string,
    voucherId: number,
    headers?: HttpHeaders
  ) {
    return this.httpHandler.httpPost(
      `/grid-by-voucher?voucherCode=${voucherCode}&voucherId=${voucherId}`,
      dataTableRequest
    );
  }

  getStats(id: number) {
    return this.httpHandler.httpGet(`/${id}/stats`);
  }

  reassign(id: number, headers?: HttpHeaders) {
    return this.httpHandler.httpPost(`/${id}/reassign`);
  }

  downloadLicense(machineUID: string, headers?: HttpHeaders) {
    return this.httpHandler.httpDownload(`/${machineUID}/download-license`);
  }
}
