<div>
    <ng-container *ngIf="authService.hasRole('CUSTOMER_CAN_READ')">
        <div class="header">
            <mat-nav-list class="sidebar-navigation">
                <a mat-list-item
                   href="..."
                   routerLink="customer"
                   routerLinkActive="active">
                    <mat-icon class="mr-2">account_circle</mat-icon>
                    {{'MENU.CUSTOMERS' | translate}}
                </a>
            </mat-nav-list>
        </div>
        <mat-divider></mat-divider>
    </ng-container>

    <mat-accordion>
        <mat-expansion-panel class="mat-elevation-z0"
                             [expanded]="true">
            <mat-expansion-panel-header class="pl-3">
                <mat-panel-title class="d-flex align-items-center">
                    <mat-icon class="mr-2">menu</mat-icon>
                    {{'MENU.COMMON_DATA' | translate}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list class="sidebar-navigation">
                <ng-container *ngFor="let item of menuList">
                    <a *ngIf="authService.hasRole(item.hasRole)"
                       mat-list-item
                       [routerLink]="item.routerLink"
                       routerLinkActive="{{item.routerLinkActive}}">
                        {{ item.label | translate}}
                    </a>
                </ng-container>

                <!-- <a mat-list-item
                  routerLink="admin"
                  routerLinkActive="active"
                  *ngIf="authService.user?.profile.name==='ADMIN'">ADMIN
                  </a> -->
            </mat-nav-list>
        </mat-expansion-panel>

    </mat-accordion>
</div>
