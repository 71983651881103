import { Identifiable } from 'app/armp/models/identifiable';
import { Profile } from 'app/armp/models/profile';

export class User extends Identifiable<number> {
  createdOn: string;
  username: string;
  displayName: string;
  domain: string;
  profileId: number;
  customerId: number;
  local: boolean;
  passwordHash: string;
  roles: Array<string>;
  profile: Profile;
}
