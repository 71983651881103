<app-page-header
  [title]="activatedRoute.snapshot.paramMap.get('id') ? 'Modifica prodotto': 'Crea nuovo prodotto'"
  [breadcrumb]="['Prodotti']">

  <div class="d-flex">
    <button mat-stroked-button
      color="primary"
      class="mr-3"
      (click)="onBack()">
      Annulla
    </button>
    <button mat-raised-button
      [disabled]="!form.valid"
      color="primary"
      (click)="save()"
      *ngIf="authService.hasRole('PRODUCT_CAN_WRITE')">
      Salva
    </button>
  </div>

</app-page-header>


<div class="container-fluid py-3">
  <form #form="ngForm"
    novalidate>

    <div class="w-75 m-auto">
      <mat-card appearance="outlined">
        <mat-card-title class="d-flex align-items-center text-primary">
          <mat-icon class="mr-2">description</mat-icon>
          Definizione prodotto
        </mat-card-title>
        <mat-card-content>

          <mat-form-field appearance="standard"
            class="w-100">
            <mat-label>Nome</mat-label>
            <input matInput
              type="text"
              maxlength="255"
              name="name"
              placeholder="Inserisci nome"
              [(ngModel)]="entity.name"
              required
              #nameModel="ngModel">
            <mat-hint align="end">{{entity?.name?.length || 0}}/255</mat-hint>

            <mat-error appFormFieldError
              [formFieldModel]="nameModel"
              [form]="form"></mat-error>
          </mat-form-field>

          <mat-form-field appearance="standard"
            class="w-100"
            *ngIf="entity.id">
            <mat-label>Codice</mat-label>
            <input matInput
              type="text"
              maxlength="255"
              name="slug"
              placeholder="Inserisci codice"
              [(ngModel)]="entity.slug"
              required
              disabled
              #slugModel="ngModel">
            <mat-hint align="end">{{entity?.slug?.length || 0}}/255</mat-hint>

            <mat-error appFormFieldError
              [formFieldModel]="slugModel"
              [form]="form"></mat-error>
          </mat-form-field>

          <mat-form-field appearance="standard"
            class="w-100">
            <mat-label>Descrizione</mat-label>
            <textarea matInput
              name="description"
              placeholder="Inserisci descrizione"
              [(ngModel)]="entity.description"
              validateMaxLength
              [validateMaxLengthValue]="255"
              maxlength="255"
              rows="4"
              #descriptionModel="ngModel"></textarea>
            <mat-hint align="end">{{entity?.description?.length || 0}}/255</mat-hint>

            <mat-error appFormFieldError
              [formFieldModel]="descriptionModel"
              [form]="form"></mat-error>
          </mat-form-field>

          <mat-form-field appearance="standard"
            class="w-100">
            <mat-label>Sottoprodotto di</mat-label>
            <mat-select #productParentIdModel="ngModel"
              name="product_parent_id"
              (selectionChange)="findParentLicenseTemplate(entity.productParentId)"
              [(ngModel)]="entity.productParentId"
              [disabled]="entity.id">
              <mat-option [value]="undefined">Seleziona il prodotto padre</mat-option>
              <mat-option *ngFor="let product of products"
                [value]="product.id">{{product.name}}</mat-option>
            </mat-select>
            <mat-error appFormFieldError
              [formFieldModel]="productParentIdModel"
              [form]="form"></mat-error>
          </mat-form-field>

          <mat-checkbox *ngIf="entity.productParentId"
            name="inheritedSlug"
            [(ngModel)]="entity.inheritedSlug"
            [disabled]="entity.id"
            class="my-3">Eredita slug dal padre</mat-checkbox>

          <mat-form-field appearance="standard"
            class="w-100">
            <mat-label>Template Licenza</mat-label>
            <mat-select #licenseTemplateIdModel="ngModel"
              name="licenseTemplateId"
              class="form-control off-bxshadow"
              [(ngModel)]="entity.licenseTemplateId"
              required>
              <mat-option [value]="undefined">Seleziona il template</mat-option>
              <mat-option *ngFor="let licenseTemplate of licenseTemplates"
                [value]="licenseTemplate.id">{{licenseTemplate.name}}</mat-option>
            </mat-select>
            <mat-error appFormFieldError
              [formFieldModel]="licenseTemplateIdModel"
              [form]="form"></mat-error>
          </mat-form-field>

        </mat-card-content>
      </mat-card>
    </div>

    <div class="w-75 m-auto pt-4">
      <mat-card appearance="outlined">
        <mat-card-title class="d-flex align-items-center text-primary">
          <mat-icon class="mr-2">vpn_key</mat-icon>
          Certificati Associati
        </mat-card-title>
        <mat-card-content>
          <app-certificate-management [(ngModel)]="certificates"
            name="certificates"
            [canManageCertificates]="authService.hasRole('PRODUCT_CAN_WRITE')">
          </app-certificate-management>
        </mat-card-content>
      </mat-card>
    </div>

  </form>
</div>