import { Injectable } from '@angular/core';
import { ApiPrivateCrudService } from 'app/armp/crud/services';

@Injectable()
export class PolicyVoucherService extends ApiPrivateCrudService<any> {

  delete(id: any): Promise<boolean> {
    return this.httpHandler.httpDelete('/' + id);
  }

  resourceName(): string {
    return 'policy-voucher';
  }
}
