import { Component, OnInit, Input, ElementRef, AfterViewInit } from '@angular/core';
import { AbstractControl, NgControl, FormControl, NgForm, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-control-error',
  templateUrl: './form-control-error.component.html',
  styleUrls: ['./form-control-error.component.scss'],
})
export class FormControlErrorComponent implements OnInit {
  @Input()
  form: UntypedFormGroup | NgForm;

  @Input()
  control: string | AbstractControl;

  @Input()
  inline = false;

  @Input()
  showError = true;

  @Input()
  errorContainer: ElementRef | HTMLElement;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {}

  get formControl(): AbstractControl {
    if (!this.form) {
      return null;
    }

    if (this.control instanceof AbstractControl) {
      return this.control;
    } else {
      return this.form.controls[this.control];
    }
  }

  get errorMessages() {
    if (!this.formControl || !this.formControl.errors) {
      return [];
    }

    const errors = new Array<FormControlError>();

    Object.keys(this.formControl.errors).forEach((errorType) => {
      const error = this.formControl.errors[errorType];
      const e = new FormControlError();
      e.type = errorType;
      e.message = 'VALIDATION.' + errorType.toUpperCase();
      if (typeof error === 'object') {
        e.data = error;
        if (error.message) {
          e.message = error.message;
        }
      }
      errors.push(e);
    });

    return errors;
  }

  get showErrors(): boolean {
    let res = false;
    if (this.formControl) {
      res = this.formControl.invalid && this.formControl.touched;
    }

    const c = this.getErrorContainer();

    if (c) {
      c.className = c.className.replace(' has-error', '');
      if (res) {
        c.className += ' has-error';
      }
    }

    if (!this.showError) {
      return false;
    }
    return res;
  }

  getErrorContainer() {
    if (this.errorContainer) {
      return this.errorContainer;
    }

    let c = this.elementRef.nativeElement.closest('.form-group,.checkbox,.checkbox-inline');

    if (!c) {
      c = this.elementRef.nativeElement.parentNode;
    }

    return c;
  }
}

class FormControlError {
  type: string;
  message: string;
  data: any;
}
