import { Injectable } from '@angular/core';
import { System } from 'app/armp/models/system';

import { ApiPrivateCrudService } from 'app/armp/crud/services';

@Injectable()
export class SystemService extends ApiPrivateCrudService<System> {
  resourceName(): string {
    return 'system';
  }
}
