import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {finalize} from 'rxjs/operators';

import {TranslateService} from '@ngx-translate/core';

import {VersionService} from '../../../services/version.service';
import {VoucherFull} from '../../../models/voucherFull';
import {VersionResponse} from '../../../models/response/versionResponse';
import {VersionArray, VersionRequest} from '../../../models/request/versionRequest';
import {ToastService} from '../../../services/toast.service';
import {LoaderService} from '../../../core/loader.service';
import {ClassifierForm, VersionForm} from './FormVersionTypes';
import {Version} from '../../../models/version';
import {VersionList} from '../../../models/versionList';

@Component({
    selector: 'app-voucher-version',
    templateUrl: './voucher-version.component.html',
    styleUrls: ['./voucher-version.component.scss']
})
export class VoucherVersionComponent implements OnInit {

    @Input() voucher: VoucherFull;
    @Input() isReadonly: boolean = true;

    versionResponse?: VersionResponse;
    formGroup!: FormGroup<VersionForm>;
    allVersionList: Map<string, Version[]> = new Map<string, Version[]>();

    constructor(private versionService: VersionService,
                private loaderService: LoaderService,
                private formBuilder: FormBuilder,
                private translateService: TranslateService,
                private toastService: ToastService) {
    }

    ngOnInit(): void {
        this.getVersionList();
    }

    private getVersionList(): void {
        this.versionService.getVersionByVoucher(this.voucher.id)
            .subscribe(res => this.createForm(res));
    }

    private createForm(versionResponse: VersionResponse): void {
        const formGroup: FormGroup<VersionForm> = new FormGroup<VersionForm>({
            voucherId: new FormControl<number | null>(this.voucher.id, Validators.required),
            lastVersion: new FormControl<boolean | null>(!!versionResponse.lastVersion, Validators.required),
            version: new FormArray<FormGroup<ClassifierForm>>([])
        });

        versionResponse.versions.forEach(version => {
            this.setVersionList(version);
            const versionForm: FormGroup<ClassifierForm> = this.formBuilder.group({
                classifier: new FormControl({value: version.classifier, disabled: true}, Validators.required),
                version: new FormControl(version.selected, Validators.required)
            });

            formGroup.controls.version.push(versionForm);
        });

        this.versionResponse = versionResponse;
        this.formGroup = formGroup;
    }

    get formArrayVersion(): FormArray<FormGroup<ClassifierForm>> {
        return this.formGroup.controls.version as FormArray;
    }

    private setVersionList(versions: VersionList) {
        if (versions?.classifier && versions?.list?.length) {
            const versionList: Version[] = this.addVersionSelected(versions);
            this.allVersionList.set(versions.classifier, versionList);
        } else {
            console.warn(`Nessuna versione disponibile per: ${versions?.classifier} - ${JSON.stringify(versions?.list)}`);
        }
    }

    private addVersionSelected(versions: VersionList): Version[] {
        const versionList: Version[] = versions?.list ?? [];
        let isInList: boolean = false;

        if (versions.selected?.id) {
            isInList = versions.list.some(v => v.id === versions.selected.id);

            if (!isInList) {
                versionList.push(versions.selected);
            }
        }


        return versionList;
    }

    compareOgg(ogg1?: Version, ogg2?: Version): boolean {
        if (!ogg1?.id || !ogg2?.id) {
            return false;
        }

        return ogg1.id === ogg2.id;
    }

    saveVersion() {
        if (!this.formGroup.controls.voucherId.value) {
            console.error('invalid voucherId');
            return;
        }


        if (!this.formGroup.controls.lastVersion.value && this.formGroup.controls.version.invalid) {
            this.formArrayVersion.controls.forEach(fg => {
                fg.controls.version.markAsDirty();
            });
            return;
        }

        this.loaderService.show();


        const versions: VersionArray[] = [];
        if (!this.formGroup.controls.lastVersion.value) {
            const formVersion = this.formArrayVersion.value;
            formVersion.forEach(control => {
                if (!control?.version) {
                    console.error('invalid version, control.version');
                    return;
                }

                versions.push({
                    selected: control.version.getOggSave()
                });
            });
        }

        const request: VersionRequest = {
            voucherId: this.formGroup.controls.voucherId.value,
            lastVersion: this.formGroup.controls.lastVersion.value,
            versions
        };

        this.versionService.saveNewVersion(this.voucher.id, request)
            .pipe(
                finalize(() => this.loaderService.hide())
            )
            .subscribe(
                (resp) => {
                    this.toastService.success({
                        message: this.translateService.instant('TOASTR.SAVE_SUCCESS'),
                    });
                    this.getVersionList();
                }
            );
    }

}

