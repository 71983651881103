<div>
  <div class="border-bottom pb-2">
    <p *ngIf="daysRemaining > 0 && daysActivation <= 0"><strong>{{daysRemaining}}<sup>GG</sup></strong></p>
    <div *ngIf="daysRemaining <= 0 && daysActivation <= 0">
      <mat-chip color="warn"
        selected>Scaduto</mat-chip>
    </div>
    <div *ngIf="daysActivation > 0">
      <mat-chip color="warn"
        selected>Non attivata</mat-chip>
    </div>
  </div>

  <div class="mt-2">
    <p *ngIf="status.widgetData.activationDate">
      <span class="mat-caption">Data attivazione</span>
      <strong>
        {{status.widgetData.activationDate | date:'shortDate'}}
      </strong>
    </p>

    <p *ngIf="status.widgetData.expirationDate">
      <span class="mat-caption">Data scadenza</span>
      <strong>
        {{status.widgetData.expirationDate | date:'shortDate'}}
      </strong>
    </p>
  </div>
</div>