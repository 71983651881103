<app-page-header title="Certificati"
                 [showBack]="false">

    <div class="d-flex align-items-center">
        <button class="mr-4"
                mat-raised-button
                color="primary"
                [routerLink]="['new']"
                *ngIf="authService.hasRole('CERTIFICATE_CAN_WRITE')">
            Crea Certificato
        </button>

        <bitf-search (valueChanges)="searchText = $event"
                     placeholder="Cerca certificato"
                     label="Cerca certificato"
                     [isInToolbar]="true"
                     [hasSearchIcon]="false">
        </bitf-search>
    </div>

</app-page-header>

<div class="container-fluid py-3">
    <app-dynamic-table
            [service]="certificateService"
            [columns]="dataColumns"
            [sorts]="[{prop: 'createdOn', dir:'desc'}]"
            [showDeleteBtn]="authService.hasRole('CERTIFICATE_CAN_DELETE')"
            [searchText]="searchText">

        <ng-template #dateTimeTpl
                     let-value="value">
            {{value | date: 'short'}}
        </ng-template>

        <ng-template #booleanTpl
                     let-value="value">
            <label *ngIf="value">
                Si
            </label>

            <label *ngIf="!value">
                No
            </label>
        </ng-template>
    </app-dynamic-table>
</div>
