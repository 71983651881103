import { Component, OnInit } from '@angular/core';
import { PolicyStatusComponent } from 'app/armp/voucher/policy-status/policy-status';

@Component({
  selector: 'app-policy-expiration-status',
  templateUrl: './policy-expiration-status.component.html',
  styleUrls: ['./policy-expiration-status.component.scss'],
})
export class PolicyExpirationStatusComponent
  extends PolicyStatusComponent
  implements OnInit
{
  public daysRemaining: number;

  ngOnInit(): void {
    if (this.status.widgetData.expirationDate) {
      const expirationDate = new Date(this.status.widgetData.expirationDate);
      this.daysRemaining = this.diffDays(new Date(), expirationDate);
    }
  }

  get daysActivation() {
    if (this.status.widgetData.activationDate) {
      const activationDate = new Date(this.status.widgetData.activationDate);

      return this.diffDays(new Date(), activationDate);
    } else {
      return 0;
    }
  }

  diffDays(date1, date2) {
    date1.setHours(12, 0, 0, 0);
    date2.setHours(12, 0, 0, 0);

    const diffTime = date2 - date1;
    return (
      Math.floor(Math.abs(diffTime) / (1000 * 60 * 60 * 24)) *
      Math.sign(diffTime)
    );
  }
}
