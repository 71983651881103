import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {filter} from 'rxjs/operators';

import {TranslateService} from '@ngx-translate/core';

import {PolicyVoucherService} from 'app/armp/services/policy-voucher.service';
import {PolicyService} from 'app/armp/services/policy.service';
import {PolicyEditModalComponent} from 'app/armp/policy/policy-edit-modal/policy-edit-modal.component';
import {AuthService} from 'app/armp/core/auth.service';
import {ToastService} from 'app/armp/services/toast.service';
import {VoucherFull} from '../../models/voucherFull';

@Component({
    selector: 'app-voucher-policies',
    templateUrl: './voucher-policies.component.html',
    styleUrls: ['./voucher-policies.component.scss'],
})
export class VoucherPoliciesComponent implements OnInit {
    @Input() voucher!: VoucherFull;
    @Input() readOnly!: boolean;

    @Output() policySaved: EventEmitter<any> = new EventEmitter();
    @Output() policyDeleted: EventEmitter<any> = new EventEmitter();

    displayedColumns = ['policy', 'tipo', 'indicatori'];

    policyTableCols = [];
    policyImplementations = [];
    policyEditing = {};
    newPolicy = {};

    constructor(
        private policyVoucherService: PolicyVoucherService,
        private translateService: TranslateService,
        private policyService: PolicyService,
        private dialog: MatDialog,
        public authService: AuthService,
        private toastService: ToastService
    ) {
    }

    ngOnInit() {
        if (!this.readOnly) {
            this.policyService.getImplementations().then((x) => (this.policyImplementations = x));
        }
    }

    savePolicy(policy, form?: NgForm) {
        let valid = true;
        if (form) {
            form.onSubmit(new Event(''));
            valid = !!form.valid;
        }

        if (valid) {
            const isNew = !policy.id;

            this.policyVoucherService.save(policy).then((p) => {
                this.toastService.success({message: 'Policy salvata correttamente'});
                this.policySaved.emit(p);
                if (isNew) {
                    this.voucher.policies.push(p);
                } else {
                    this.stopEditPolicy(policy);
                }
            });
        }
    }

    deletePolicy(policy) {
        if (confirm(this.translateService.instant('VOUCHER.DELETE_POLICY_CONFIRM', {name: policy.name}))) {
            this.policyVoucherService.delete(policy.id).then((x) => {
                this.policyDeleted.emit(policy);
            });
        }
    }

    addPolicy() {
        this.dialog
            .open(PolicyEditModalComponent, {
                width: 'auto',
                minWidth: '300px',
                data: {},
            })
            .afterClosed()
            .pipe(filter((response) => response))
            .subscribe((policy) => {
                policy.voucherId = this.voucher.id;
                this.savePolicy(policy);
            });
    }

    canDeletePolicy(policy) {
        if (
            this.readOnly ||
            this.isEditingPolicy(policy) ||
            !this.authService.hasRole('POLICY_VOUCHER_CAN_DELETE')
        ) {
            return false;
        }

        if (!policy.id) {
            return true;
        }

        if (policy.implementation && policy.implementation.required) {
            return false;
        }

        return true;
    }

    canEditPolicy(policy) {
        return !this.readOnly && this.authService.hasRole('POLICY_VOUCHER_CAN_WRITE');
    }

    isEditingPolicy(policy) {
        return !!this.policyEditing[policy.id];
    }

    startEditPolicy(policy) {
        this.policyEditing[policy.id] = Object.assign({}, policy);
    }

    stopEditPolicy(policy) {
        delete this.policyEditing[policy.id];
    }

    undoPolicyEdit(policy) {
        const old = this.policyEditing[policy.id];
        if (old) {
            Object.assign(policy, old);
            this.stopEditPolicy(policy);
        }
    }

    get policies() {
        return this.voucher.policies;
    }

    getAvailablePolicyImplementations(policyIndex?: number): Array<any> {
        return this.policyService.getAvailablePolicyImplementations(
            this.policyImplementations,
            this.voucher.policies,
            policyIndex
        );
    }

    onImplementationChange(policy) {
        policy.code = null;
        if (policy.implementation) {
            policy.code = policy.implementation.code;
        }
    }
}
