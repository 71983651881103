import { Injectable } from '@angular/core';

import { ApiPrivateService } from 'app/armp/crud/services';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class InternalResourceService extends ApiPrivateService {
  resourceName(): string {
    return 'internal-resource';
  }

  search(query: string) {
    const params = new FormData();
    params.set('query', query);
    return this.httpHandler.httpPostUrlEncoded('/search', params);
  }
}
