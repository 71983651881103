import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { RoleService } from 'app/armp/services/role.service';
import { Role } from 'app/armp/models/role';
import { AuthService } from 'app/armp/core/auth.service';
import { LoaderService } from 'app/armp/core/loader.service';
import { ToastService } from 'app/armp/services/toast.service';

@Component({
  selector: 'app-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.scss'],
})
export class RoleEditComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: false }) private form: NgForm;

  entity: any;

  private sub: any;

  constructor(
    public activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private roleService: RoleService,
    private location: Location,
    private loaderService: LoaderService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.entity = new Role();
    this.sub = this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.roleService.getById(+params.id).then((p) => {
          this.entity = p;
        });
      }
    });
  }

  onBack() {
    this.location.back();
  }

  save() {
    this.form.onSubmit(null);
    if (this.form.valid) {
      this.loaderService.show();

      this.roleService
        .save(this.entity)
        .then(() => {
          this.loaderService.hide();
          this.toastService.success({
            message: this.translateService.instant('TOASTR.SAVE_SUCCESS'),
          });
          this.onBack();
        })
        .catch(() => {
          this.loaderService.hide();
        });
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
