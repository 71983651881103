import { Directive, Input } from '@angular/core';
import { FormValidators } from 'app/armp/shared/form-validators';
import { CustomValidatorDirective } from 'app/armp/models/custom-validator';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';
@Directive({
  selector: `[validateMin][formControlName],[validateMin][formControl],[validateMin][ngModel]`,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MinDirective,
      multi: true,
    },
  ],
})
export class MinDirective extends CustomValidatorDirective {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('validateMin') enabled: any;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('validateMinValue') min: number;

  protected _validate(formControl: AbstractControl): ValidationErrors {
    return FormValidators.minValidator(formControl.value, this.min);
  }
}
