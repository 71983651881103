import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';

import { CertificateBindingService } from 'app/armp/services/certificate-binding.service';
import { CustomerService } from 'app/armp/services/customer.service';
import { Page } from 'app/armp/models/page';
import { VoucherService } from 'app/armp/services/voucher.service';
import { ProductService } from 'app/armp/services/product.service';
import { AuthService } from 'app/armp/core/auth.service';
import { ToastService } from 'app/armp/services/toast.service';
import { ActivatedRoute } from '@angular/router';
import { DynamicTableComponent } from 'app/armp/crud/dynamic-table/dynamic-table.component';
import { IDynamicTableColumnData } from 'common/interfaces/dynamic-column.interface';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.scss'],
})
export class CustomerEditComponent implements OnInit {
  customerInitialValue: any;
  customer: any;
  editMode = false;
  certificates = [];
  vouchersCount: any = {};
  activeTab = 0;
  tabs = ['Voucher', 'Prodotti'];
  voucherCols: IDynamicTableColumnData[] = [];
  productCols: IDynamicTableColumnData[] = [];

  voucherSearchText: any;
  voucherPage: Page<any>;

  productSearchText: any;
  productPage: Page<any>;

  @ViewChild('statusColTpl', { static: true }) statusColTpl: TemplateRef<any>;
  @ViewChild('voucherActiveTmpl', { static: true })
  voucherActiveTmpl: TemplateRef<any>;
  @ViewChild('dateTpl', { static: true }) dateTpl: TemplateRef<any>;
  @ViewChild('dateTimeTpl', { static: true }) dateTimeTpl: TemplateRef<any>;
  @ViewChild('voucherGrid', { static: true })
  voucherGrid: DynamicTableComponent;
  @ViewChild('productGrid', { static: true })
  productGrid: DynamicTableComponent;

  constructor(
    private customerService: CustomerService,
    private certificateBindingService: CertificateBindingService,
    private toastService: ToastService,
    public voucherService: VoucherService,
    public productService: ProductService,
    public authService: AuthService,
    private _route: ActivatedRoute
  ) {
    this.customer = this._route.snapshot.data['customer'] || {};
    this.customerInitialValue = { ...this.customer };
  }

  ngOnInit() {
    this.certificateBindingService
      .getCertificatesByCustomerId(this.customer.id)
      .then((response) => {
        this.certificates = response;
      });

    this.customerService
      .countByCustomerId(this.customer.id)
      .then((response) => {
        this.vouchersCount = response;
      });

    this.voucherCols.push(
      {
        name: 'Codice',
        prop: 'code',
        sortable: true,
        styleHeader: { width: '230px' },
      },
      {
        name: 'Status',
        prop: 'status',
        sortable: true,
        searchable: false,
        cellTemplate: this.statusColTpl,
        styleHeader: { width: '120px' },
      },
      {
        name: 'Attivo',
        prop: 'active',
        sortable: true,
        searchable: false,
        cellTemplate: this.voucherActiveTmpl,
        styleHeader: { width: '105px' },
      },
      {
        name: 'Cliente',
        prop: 'customerDisplayName',
        sortable: false,
        searchable: false,
        styleHeader: { width: '200px' },
      },
      {
        name: 'Prodotto',
        prop: 'productName',
        sortable: true,
        searchable: false,
      },
      {
        name: 'Creato il',
        prop: 'createdOn',
        sortable: true,
        searchable: false,
        cellTemplate: this.dateTimeTpl,
        styleHeader: { width: '130px' },
      },
      {
        name: 'Scade il',
        prop: 'expiresOn',
        sortable: true,
        searchable: false,
        cellTemplate: this.dateTpl,
        styleHeader: { width: '90px' },
      },
      {
        name: '# Licenze',
        prop: 'totalLicenseInstances',
        sortable: false,
        searchable: false,
        styleHeader: { width: '60px' },
      }
    );

    this.productCols.push(
      {
        name: 'Codice',
        prop: 'slug',
        sortable: true,
      },
      {
        name: 'Nome',
        prop: 'name',
        sortable: true,
      },
      {
        name: 'Descrizione',
        prop: 'description',
        sortable: true,
      }
    );
  }

  editCustomer() {
    this.editMode = true;
  }

  updateCustomer() {
    this.customer.certificates = this.certificates.map((x) => {
      const tmp = {
        id: null,
      };
      Object.assign(tmp, x);

      return tmp;
    });

    this.customerService.save(this.customer).then((x) => {
      this.editMode = false;
      this.customerInitialValue = { ...this.customer };
    });
  }

  saveCertificates() {
    this.customerService
      .saveCertificateBindings(this.customer.id, this.certificates)
      .then(() => {
        this.toastService.success({ message: 'Certificati Aggiornati' });
      });
  }

  fetchVouchers(event: any) {
    if (this.customer.id) {
      event.promise = this.voucherService.getGridByCustomer(
        event.dataTableRequest,
        this.customer.id
      );
    }
  }

  vouchersFetched(page: Page<any>) {
    this.voucherPage = page;
  }

  searchVoucher() {
    this.voucherGrid.searchText = this.voucherSearchText;
    this.voucherGrid.fetchPage();
  }

  fetchProducts(event: any) {
    if (this.customer.id) {
      event.promise = this.productService.getGridByCustomer(
        event.dataTableRequest,
        this.customer.id
      );
    }
  }

  productsFetched(page: Page<any>) {
    this.productPage = page;
  }

  searchProduct() {
    this.productGrid.searchText = this.productSearchText;
    this.productGrid.fetchPage();
  }
}
