import { Component, OnInit } from '@angular/core';
import { PolicyStatusComponent } from 'app/armp/voucher/policy-status/policy-status';
import { CONSTANTS } from 'common/constants/constants';
import { GoogleChartInterface } from 'ng2-google-charts';

@Component({
  selector: 'app-policy-base-consumption-status',
  templateUrl: './policy-base-consumption-status.component.html',
  styleUrls: ['./policy-base-consumption-status.component.scss'],
})
export class PolicyBaseConsumptionStatusComponent
  extends PolicyStatusComponent
  implements OnInit
{
  usedPercentage: number;
  chartData: any = [];
  chartOptions: GoogleChartInterface['options'] = {
    pieHole: 0.7,
    legend: 'none',
  };

  private _defaultColor = CONSTANTS.COLORS.lightGrey;
  private _colors = [CONSTANTS.COLORS.darkRed, CONSTANTS.COLORS.grey];
  private _randomColors = [
    CONSTANTS.COLORS.darkRed,
    CONSTANTS.COLORS.green,
    CONSTANTS.COLORS.lightBlue,
    CONSTANTS.COLORS.orange,
  ];

  ngOnInit() {
    var currentColor =
      this._randomColors[Math.floor(Math.random() * this._randomColors.length)];
    this._colors[0] = currentColor;

    const remaining =
      this.status.widgetData.total - this.status.widgetData.actual;

    if (this.status.widgetData.actual + this.status.widgetData.total === 0) {
      this.usedPercentage = 100;
    } else {
      this.usedPercentage =
        (this.status.widgetData.actual / this.status.widgetData.total) * 100;
    }

    this.chartData = [
      ['', ''],
      ['Actual', this.usedPercentage],
      ['Rimanenti', remaining],
    ];

    if (this.usedPercentage === 0) {
      this.chartOptions.colors = [this._defaultColor];
      this.chartData[1][1] = 100;
    } else {
      this.chartOptions.colors = this._colors;
    }
  }
}
