import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { PolicyService } from 'app/armp/services/policy.service';

@Component({
  selector: 'app-policy-edit-modal',
  templateUrl: './policy-edit-modal.component.html',
  styleUrls: ['./policy-edit-modal.component.scss'],
})
export class PolicyEditModalComponent implements OnInit {
  @Input() policy = {} as any;
  @Input() voucher;
  @Input() licenseInstance;

  @ViewChild('form', { static: true }) form!: NgForm;

  policyImplementations = [];

  constructor(
    private policyService: PolicyService,
    public dialogRef: MatDialogRef<PolicyEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    let p: Promise<any>;

    if (this.voucher) {
      p = this.policyService.getVoucherImplementations();
    } else if (this.licenseInstance) {
      p = this.policyService.getLicenseInstanceImplementations();
    } else {
      p = this.policyService.getImplementations();
    }

    p.then((x) => (this.policyImplementations = x));
  }

  onConfirm() {
    this.form.onSubmit(null);
    if (this.form.valid) {
      this.dialogRef.close(this.policy);
    }
  }

  get availablePolicyImplementations() {
    return this.policyService.getAvailablePolicyImplementations(this.policyImplementations, this.policies);
  }

  get policies() {
    if (this.voucher) {
      return this.voucher.policies;
    } else if (this.licenseInstance) {
      return this.licenseInstance.policies;
    } else {
      return [];
    }
  }
}
