import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CertificateManagementComponent} from './certificate-management/certificate-management.component';
import {CertificateListComponent} from './certificate-list/certificate-list.component';
import {CertificateCreateComponent} from './certificate-create/certificate-create.component';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {RouterLink} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {DynamicTableModule} from '../crud/dynamic-table/dynamic-table.module';
import {FormValidatorModule} from '../shared/form-validator/form-validator.module';


@NgModule({
    declarations: [
        CertificateManagementComponent,
        CertificateListComponent,
        CertificateCreateComponent,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        FormsModule,
        SharedModule,
        RouterLink,
        TranslateModule.forChild(),
        DynamicTableModule,
        FormValidatorModule
    ],
    exports: [
        CertificateManagementComponent,
        CertificateListComponent,
        CertificateCreateComponent,
    ]
})
export class CertificateModule {
}
