import { Injectable } from '@angular/core';

import { Certificate } from 'app/armp/models/certificate';

import { ApiPrivateCrudService } from 'app/armp/crud/services';

@Injectable()
export class CertificateService extends ApiPrivateCrudService<object> {
  resourceName(): string {
    return 'certificate';
  }

  saveCertificate(cert: Certificate, file: File) {
    const data = new FormData();
    data.append('certificateFile', file);
    data.append('certificate', JSON.stringify(cert));

    return this.httpHandler.httpPost('/save_certificate', data);
  }

  getCertificateTypes() {
    return this.httpHandler.httpGet('/certificateTypes');
  }

  getCertificatesByType(type: any) {
    return this.httpHandler.httpGet('/certificates/' + type);
  }

  downloadCertificate(id: number) {
    return this.httpHandler.httpDownload(`/${id}/download-certificate`);
  }
}
