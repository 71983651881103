<app-page-header title="Clienti"
  [showBack]="false">

  <div class="d-flex align-items-center">
    <button class="mr-4"
      mat-raised-button
      color="primary"
      [routerLink]="['new']"
      *ngIf="authService.hasRole('CUSTOMER_CAN_WRITE')">
      Crea cliente
    </button>

    <bitf-search (valueChanges)="searchText = $event"
      placeholder="Cliente"
      label="Cerca cliente"
      [isInToolbar]="true"
      [hasSearchIcon]="false">
    </bitf-search>
  </div>

</app-page-header>

<div class="container-fluid py-3">
  <app-dynamic-table [service]="customerService"
    [columns]="dataColumns"
    [showDeleteBtn]="authService.hasRole('CUSTOMER_CAN_DELETE')"
    [searchText]="searchText">
  </app-dynamic-table>

  <ng-template #createdOnCellTemplate
    let-row="row"
    let-value="value">
    {{value | date:'shortDate'}}
  </ng-template>

  <ng-template #vatNumberCellTemplate
    let-row="row">
    {{(row.organization) ? row.vatNumber : row.fiscalCode}}
  </ng-template>
</div>