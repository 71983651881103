import {HttpClient} from '@angular/common/http';
import {DataTableRequest} from 'app/armp/models/datatable-request';
import {Page} from 'app/armp/models/page';

import {Injectable} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

import {CruService} from 'app/armp/crud/services/cru-service';
import {CrudService} from 'app/armp/crud/services/crud-service';
import {ReadOnlyService} from 'app/armp/crud/services/ro-service';
import {ApiHttpHandler} from 'app/armp/core/api/api-http-handler';
import {ApiService} from 'app/armp/core/api/api.service';
import {ToastService} from 'app/armp/services/toast.service';
import {ConfigService} from '../../services/config.service';

export class ReadOnlyServiceImpl<T> implements ReadOnlyService<T> {
    constructor(protected httpHandler: ApiHttpHandler) {
    }

    getAll(): Promise<T[]> {
        return this.httpHandler.httpGet('');
    }

    getGrid(request: DataTableRequest): Promise<Page<T>> {
        return this.httpHandler.httpPost('/grid', request);
    }

    getById(id: any): Promise<T> {
        return this.httpHandler.httpGet('/' + id);
    }
}

// prettier-ignore
export class CruServiceImpl<T extends { id?: number | string | undefined }>
    extends ReadOnlyServiceImpl<T>
    implements CruService<T> {
    save(obj: T): Promise<T> {
        if ('id' in obj && obj['id']) {
            return this.update(obj);
        } else {
            return this.create(obj);
        }
    }

    create(obj: T): Promise<T> {
        return this.httpHandler.httpPost('/', obj);
    }

    update(obj: T): Promise<T> {
        return this.httpHandler.httpPut('/' + obj.id, obj);
    }
}

// @ts-ignore
export class CrudServiceImpl<T extends any> extends CruServiceImpl<T> implements CrudService<T> {
    delete(id: any): Promise<boolean> {
        return this.httpHandler.httpDelete('/' + id);
    }
}

@Injectable()
export abstract class ApiPrivateService extends ApiService {
    constructor(
        private configService: ConfigService,
        toastService: ToastService,
        http: HttpClient,
        translator: TranslateService) {
        super(configService.config.apiEndpoint, toastService, http, translator);
    }
}

@Injectable()
export abstract class ApiPrivateReadOnlyService<T> extends ApiPrivateService implements ReadOnlyService<T> {
    crudDelegate: ReadOnlyServiceImpl<T> = new ReadOnlyServiceImpl(this.httpHandler);

    getAll(): Promise<T[]> {
        return this.crudDelegate.getAll();
    }

    getGrid(request: DataTableRequest): Promise<Page<T>> {
        return this.crudDelegate.getGrid(request);
    }

    getById(id: any): Promise<T> {
        return this.crudDelegate.getById(id);
    }
}

@Injectable()
export abstract class ApiPrivateCruService<T> extends ApiPrivateReadOnlyService<T> implements CruService<T> {
    crudDelegate: CruServiceImpl<T> = new CruServiceImpl(this.httpHandler);

    save(obj: T): Promise<T> {
        return this.crudDelegate.save(obj);
    }

    create(obj: T): Promise<T> {
        return this.crudDelegate.create(obj);
    }

    update(obj: T): Promise<T> {
        return this.crudDelegate.update(obj);
    }
}

@Injectable()
export abstract class ApiPrivateCrudService<T> extends ApiPrivateCruService<T> implements CrudService<T> {
    crudDelegate: CrudServiceImpl<T> = new CrudServiceImpl(this.httpHandler);

    delete(id: any): Promise<boolean> {
        return this.crudDelegate.delete(id);
    }
}

@Injectable()
export abstract class ApiPublicService extends ApiService {
    constructor(
        private configService: ConfigService,
        toastService: ToastService,
        http: HttpClient,
        translator: TranslateService) {
        super(configService.config.apiPublicEndpoint, toastService, http, translator);
    }
}

@Injectable()
export abstract class ApiPublicReadOnlyService<T> extends ApiPublicService implements ReadOnlyService<T> {
    crudDelegate: ReadOnlyServiceImpl<T> = new ReadOnlyServiceImpl(this.httpHandler);

    getAll(): Promise<T[]> {
        return this.crudDelegate.getAll();
    }

    getGrid(request: DataTableRequest): Promise<Page<T>> {
        return this.crudDelegate.getGrid(request);
    }

    getById(id: any): Promise<T> {
        return this.crudDelegate.getById(id);
    }
}

@Injectable()
export abstract class ApiPublicCruService<T> extends ApiPublicReadOnlyService<T> implements CruService<T> {
    crudDelegate: CruServiceImpl<T> = new CruServiceImpl(this.httpHandler);

    save(obj: T): Promise<T> {
        return this.crudDelegate.save(obj);
    }

    create(obj: T): Promise<T> {
        return this.crudDelegate.create(obj);
    }

    update(obj: T): Promise<T> {
        return this.crudDelegate.update(obj);
    }
}

export abstract class ApiPublicCrudService<T> extends ApiPublicCruService<T> implements CrudService<T> {
    crudDelegate: CrudServiceImpl<T> = new CrudServiceImpl(this.httpHandler);

    delete(id: any): Promise<boolean> {
        return this.crudDelegate.delete(id);
    }
}
