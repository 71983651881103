import {
  Directive,
  Input,
  TemplateRef,
  ElementRef,
  OnInit,
  HostListener,
  ComponentRef,
  OnDestroy,
} from '@angular/core';
import { ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { BitfTooltipContainerComponent } from '../bitf-tooltip-container/bitf-tooltip-container.component';

@Directive({
  selector: '[bitfTooltip]',
})
export class BitfTooltipDirective implements OnInit, OnDestroy {
  // This can be used to disable tooltip conditionally
  @Input() showToolTip?: boolean = true;

  // If this is specified then specified text will be showin in the tooltip
  @Input(`bitfTooltip`) text: string = '';

  // If this is specified then specified template will be rendered in the tooltip
  @Input() tooltipTemplate?: TemplateRef<any>;

  // Specified position
  @Input() connectedPosition?: ConnectedPosition;

  // Specified single position
  @Input() originX: 'start' | 'center' | 'end' = 'end';
  @Input() originY: 'top' | 'center' | 'bottom' = 'center';
  @Input() overlayX: 'start' | 'center' | 'end' = 'start';
  @Input() overlayY: 'top' | 'center' | 'bottom' = 'center';
  @Input() offsetX = 0;
  @Input() offsetY = 0;

  // Custom tooltip container class
  @Input() tooltipContainerClass?: string;

  private _overlayRef!: OverlayRef;

  constructor(
    private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef
  ) {}

  ngOnInit() {
    let currentConnectedPosition = {
      originX: this.originX,
      originY: this.originY,
      overlayX: this.overlayX,
      overlayY: this.overlayY,
      offsetX: this.offsetX,
      offsetY: this.offsetY,
    };
    if (this.connectedPosition) {
      currentConnectedPosition = {
        ...currentConnectedPosition,
        ...this.connectedPosition,
      };
    }
    const positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(this._elementRef)
      .withPositions([currentConnectedPosition]);

    this._overlayRef = this._overlay.create({ positionStrategy });
  }

  /**
   * Show the tooltip
   */
  @HostListener('mouseenter') show() {
    if (!this.showToolTip) {
      return;
    }
    //attach the component if it has not already attached to the overlay
    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      const tooltipRef: ComponentRef<BitfTooltipContainerComponent> = this._overlayRef.attach(
        new ComponentPortal(BitfTooltipContainerComponent)
      );
      tooltipRef.instance.text = this.text;
      tooltipRef.instance.tooltipTemplate = this.tooltipTemplate;
      tooltipRef.instance.tooltipContainerClass = this.tooltipContainerClass ?? '';
    }
  }

  /**
   * Hide the tooltip
   */
  @HostListener('mouseleave') hide() {
    this.closeToolTip();
  }

  /**
   * Close the tooltip by detaching the component from the overlay
   */
  private closeToolTip() {
    if (this._overlayRef) {
      this._overlayRef.detach();
    }
  }

  ngOnDestroy() {
    this.closeToolTip();
  }
}
