import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {AbstractControl, NgForm, NgModel, ValidationErrors} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Directive({
    selector: '[appFormFieldError]',
})
export class FormFieldErrorDirective implements OnInit {
    @Input('formFieldModel') formFieldModel?: NgModel;
    @Input('formControl') formControl?: string | AbstractControl;
    @Input('form') form?: NgForm;

    private _control: NgModel | AbstractControl;

    constructor(private elementRef: ElementRef, private translateService: TranslateService) {
    }

    ngOnInit(): void {
        if (this.formFieldModel) {
            this._control = this.formFieldModel;
        } else if (this.formControl) {
            this._control = this._formControl;
        }

        this._control?.statusChanges.subscribe((status) => {
            if (this.form?.submitted) {
                this.toggleStatus();
            }
        });

        this._control.valueChanges.subscribe((value) => {
            this.toggleStatus();
        });
    }

    private async toggleStatus() {
        if (this._control && this.formFieldModel.status === 'INVALID') {
            this.elementRef.nativeElement.style.display = 'block';
            this.elementRef.nativeElement.innerHTML = await this.errorMessages(this._control.errors);
        } else {
            this.elementRef.nativeElement.style.display = 'none';
        }
    }

    private async errorMessages(errors: ValidationErrors): Promise<string> {
        if (!this.formFieldModel?.errors) {
            return '';
        }

        let errorsList = [];
        await Object.keys(errors).forEach(async (errorType) => {
            const _error = errors[errorType];
            let error: any = {};
            error.message = 'VALIDATION.' + errorType.toUpperCase();
            if (typeof _error === 'object') {
                error.data = _error;
                if (_error.message) {
                    error.message = _error.message;
                }
            }

            const translatedMessage = await this.translateService.get(error.message, error.data).toPromise();
            errorsList.push(translatedMessage);
        });
        return errorsList.join(', ');
    }

    private get _formControl(): AbstractControl {
        if (!this.form) {
            return null;
        }

        if (this.formControl instanceof AbstractControl) {
            return this.formControl;
        } else {
            return this.form.controls[this.formControl];
        }
    }
}
