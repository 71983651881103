export const dateDifference = (date2, date1) => {
  if (!date2 || !date1) {
    return null;
  }
  const msDay = 1000 * 60 * 60 * 24;

  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

  return Math.ceil((utc2 - utc1) / msDay);
};
