<app-page-header [title]="'Amministrazione'"
  [breadcrumb]="breadcrumb">
</app-page-header>

<div class="container-fluid py-3">

  <form #form="ngForm"
    novalidate>

    <div class="w-75 m-auto">
      <mat-card appearance="outlined">
        <mat-card-title class="d-flex align-items-center text-primary">
          <mat-icon class="mr-2">rocket_launch</mat-icon>
          Migrazione
        </mat-card-title>

        <mat-card-content>


          <div [ngSwitch]="status"
            *ngIf="status">

            <app-alert *ngSwitchCase="'NOT_STARTED'"
              class="m-3"
              type="info"
              text="Migrazione non ancora eseguita"></app-alert>

            <app-alert *ngSwitchCase="'RUNNING'"
              class="m-3"
              type="warn"
              text="Migrazione in corso"></app-alert>

            <app-alert *ngSwitchCase="'COMPLETED'"
              class="m-3"
              type="success"
              text="Migrazione completata con successo"></app-alert>

            <app-alert *ngSwitchCase="'ERROR'"
              class="m-3"
              type="error"
              text="Migrazione fallita"></app-alert>

          </div>
        </mat-card-content>
        <mat-card-actions>
          <button type="button"
            mat-raised-button
            color="primary"
            (click)="startMigration()"
            *ngIf="canMigrate()">
            {{status==='RUNNING' ? 'Attendere' : 'Migra'}}
          </button>
        </mat-card-actions>

        <mat-card-footer *ngIf="status==='RUNNING'">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
      </mat-card>
    </div>

  </form>

</div>