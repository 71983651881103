import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-bitf-tooltip-container',
  templateUrl: './bitf-tooltip-container.component.html',
  styleUrls: ['./bitf-tooltip-container.component.scss'],
})
export class BitfTooltipContainerComponent {
  /**
   * This is simple text which is to be shown in the tooltip
   */
  @Input() text?: string;

  /**
   the tooltip directive can pass a template for custom and complex tooltip
   */
  @Input() tooltipTemplate?: TemplateRef<any>;

  @Input() tooltipContainerClass: string = '';

  constructor() {}
}
