import { Injectable } from '@angular/core';
import { ApiPrivateCruService, ApiPrivateCrudService } from 'app/armp/crud/services';

@Injectable()
export class CertificateBindingService extends ApiPrivateCrudService<object> {
  resourceName(): string {
    return 'certificate-binding';
  }

  getCertificatesByProductId(productId: number) {
    return this.httpHandler.httpGet('/product_id/' + productId);
  }

  getCertificatesByCustomerId(customerId: number) {
    return this.httpHandler.httpGet('/customer_id/' + customerId);
  }

  getCertificatesByVoucherId(voucherId: number) {
    return this.httpHandler.httpGet('/voucher_id/' + voucherId);
  }
}
