import { Injectable } from '@angular/core';
import { InformationType } from 'app/armp/models/information-type';

import { ApiPrivateCrudService, ApiPrivateCruService } from 'app/armp/crud/services';

@Injectable()
export class InformationTypeService extends ApiPrivateCrudService<InformationType> {
  resourceName(): string {
    return 'information-type';
  }
}
