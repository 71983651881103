import { Injectable } from '@angular/core';

import { Role } from 'app/armp/models/role';

import { ApiPrivateCrudService, ApiPrivateCruService } from 'app/armp/crud/services';

@Injectable()
export class RoleService extends ApiPrivateCruService<Role> {

  getAll(): Promise<Role[]> {
    return this.httpHandler.httpGet('');
  }

  resourceName(): string {
    return 'role';
  }
  getByProfileId(profileId: number) {
    return this.httpHandler.httpGet('/profile/' + profileId);
  }

  delete() {
    // not implemented
    return Promise.reject(false);
  }
}
