<div class="position-relative">
    <div class="chart-value position-absolute d-flex flex-column justify-content-center"
         [ngStyle]="{'left': centerPositionX + 'px'}">
        <ng-content select="[centerContainer]"></ng-content>
    </div>
    <google-chart
        #chart
        [data]="dataChart"
        (chartReady)='chartIsReady()'>
    </google-chart>
</div>
