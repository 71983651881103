<app-page-header
  [title]="activatedRoute.snapshot.paramMap.get('id') ? 'Modifica profilo': 'Crea nuovo profilo'"
  [breadcrumb]="['Profili']">

  <div class="d-flex">
    <button mat-stroked-button
      color="primary"
      class="mr-3"
      (click)="onBack()">
      Annulla
    </button>
    <button mat-raised-button
      [disabled]="!form.valid"
      color="primary"
      (click)="save()"
      *ngIf="authService.hasRole('PRODUCT_CAN_WRITE')">
      Salva
    </button>
  </div>

</app-page-header>

<div class="container-fluid py-3">
  <form #form="ngForm"
    novalidate>

    <div class="w-75 m-auto">
      <mat-card appearance="outlined">
        <mat-card-title class="d-flex align-items-center text-primary">
          <mat-icon class="mr-2">assignment_ind</mat-icon>
          Definizione Profilo
        </mat-card-title>

        <mat-card-content>

          <mat-form-field appearance="standard"
            class="w-100">
            <mat-label>Nome</mat-label>
            <input matInput
              type="text"
              maxlength="255"
              name="name"
              placeholder="Inserisci nome"
              [(ngModel)]="entity.name"
              required
              [disabled]="entity.system"
              validateMaxLength
              [validateMaxLengthValue]="255"
              #nameModel="ngModel">
            <mat-hint align="end">{{entity?.name?.length || 0}}/255</mat-hint>

            <mat-error appFormFieldError
              [formFieldModel]="nameModel"
              [form]="form"></mat-error>
          </mat-form-field>

          <mat-form-field appearance="standard"
            class="w-100">
            <mat-label>Username</mat-label>
            <textarea matInput
              name="description"
              placeholder="Inserisci descrizione"
              [(ngModel)]="entity.description"
              validateMaxLength
              [validateMaxLengthValue]="255"
              #descriptionModel="ngModel"></textarea>
            <mat-hint align="end">{{entity?.description?.length || 0}}/255</mat-hint>

            <mat-error appFormFieldError
              [formFieldModel]="descriptionModel"
              [form]="form"></mat-error>
          </mat-form-field>

          <div class="mt-2">
            <h4>Ruoli:</h4>

            <app-alert *ngIf="entity?.name === 'ADMIN'"
              class="m-3"
              type="info"
              text="Il profilo {{entity.name}} ha accesso a tutte le funzionalità"></app-alert>

            <div *ngIf="entity.name && entity.name !== 'ADMIN'"
              class="row">
              <div *ngFor="let role of roles"
                class="mt-2 col-24 col-md-8">
                <mat-checkbox [name]="'roles' + role.id"
                  [(ngModel)]="rolesSelected[role.id]">{{role.description}}</mat-checkbox>
              </div>

              <app-alert *ngIf="needRoles() && form.submitted"
                class="m-3"
                type="error"
                text="E' necessario associare almeno un ruolo"></app-alert>
            </div>

          </div>

        </mat-card-content>
      </mat-card>
    </div>

  </form>
</div>