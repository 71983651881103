import { Injectable } from '@angular/core';
import { Profile } from 'app/armp/models/profile';

import { ApiPrivateCrudService } from 'app/armp/crud/services';

@Injectable()
export class ProfileService extends ApiPrivateCrudService<Profile> {
  resourceName(): string {
    return 'profile';
  }
}
