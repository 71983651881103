<mat-toolbar class="border-bottom">
  <div class="d-flex align-items-center w-100">
    <a class="d-flex align-items-center"
      href="javascript:void(0)"
      routerLink=""><img class="img-responsive"
        src="assets/images/logo-aruba.png"
        alt="aruba logo"></a>
    <span class="flex-grow-1"></span>
    <button mat-button
      [matMenuTriggerFor]="userMenu"
      *ngIf="authService.isLoggedIn">
      <mat-icon class="mr-2">account_circle</mat-icon>
      {{authService.user?.displayName}}
    </button>
    <mat-menu #userMenu="matMenu">
      <button mat-menu-item
        (click)="logout()">Logout</button>
    </mat-menu>
  </div>
</mat-toolbar>