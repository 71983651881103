import { Injectable } from '@angular/core';

import { DataTableRequest } from 'app/armp/models/datatable-request';
import { ApiPrivateCruService } from 'app/armp/crud/services';

@Injectable()
export class InformationService extends ApiPrivateCruService<any> {
  resourceName(): string {
    return 'information';
  }

  getGridByLicenseInstance(dataTableRequest: DataTableRequest, licenseInstanceId: number) {
    return this.httpHandler.httpPost(
      '/grid-by-license-instance?licenseInstanceId=' + licenseInstanceId,
      dataTableRequest
    );
  }

  getHtml(id: number) {
    return this.httpHandler.httpGet(`/${id}/html`);
  }
}
