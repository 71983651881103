import {Identifiable} from 'app/armp/models/identifiable';

export class Product extends Identifiable<number> {
    createdOn: string;
    slug: string;
    name: string;
    description: string;
    certificates: Array<any>;
    productParentId: number;
    licenseTemplateId: number;
    inheritedSlug: boolean;
    slugGenerationMode: string;
    licenseTemplate: any;
}
